import React, { useContext } from 'react'
import '../Review/Review.scss'
import { BasketContext } from '../../Context/BasketContext/Basket'
import { AiFillQuestionCircle } from 'react-icons/ai'

const Review = ({TotalPrice , priceShipping , totalReview , selectedCurrency ,  rates  , copyInfos    }) => {



    console.log(priceShipping)



  return (
    <div className='review_section'>
        <span class="riview_order"> Votre commande  </span>
        <div class="review_infos">
            <div class="review_infos_items">
              <span> Contact</span>
              <div class="review_values">
                  <span> dodo@gmail.com  </span>
              </div>
          </div>
          <div class="divider"></div>
        <div class="review_infos_items">
            <span> Expédier à</span>
            <div class="review_values">
                <span> fes   </span>
            </div>
        </div>
        <div class="review_infos_items">
        <span> Méthode à</span>
        <div class="review_values">
            <span> Mondial relay   </span>
        </div>
    </div>
    </div>
        <div class="review_infos">
        <div className='infos_container'>
        <div className='carte_items'>
            { copyInfos.map((item) => (
                <div className='cart__item-details'>
                    <div className='cart__item-left'>
                        <div className='cate_image' >
                            <img src={item.url} alt={"Ns Caftan"}/>
                        <span className='quantity'> {item.quantity}  </span>
                        </div>
                        <div className='cate_titles' >
                            <p> {item.title} </p>
                            <span> {item.color} / {item.size} </span>
                        </div>
                    </div>
                    <div className='cart__item-right'>
                    <span> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(item.quantity * item.price * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>

                    </div>
                </div>
            ))
            }
         </div>
{  priceShipping === 1 ?
         <div className='items_total'>
            <div className='section'>
                    <span>Sous-total</span>
                    <span className='subtotal' > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(totalReview).toFixed(2)}  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>
            </div>
              <div className='section'>
                    <span>Expédition   <AiFillQuestionCircle id='icon'/> </span>
                    <span style={{fontSize:"11px" , color:"rgb(122, 122, 122)"}} > Calculé à l'étape suivante </span>
            </div>
         </div>
        :
         <div className='items_total'>
         <div className='section'>
                 <span>Sous-total</span>
                 <span className='subtotal' > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(totalReview ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>
         </div>
           <div className='section'>
                 <span  >Livraison   <AiFillQuestionCircle id='icon'/> </span>
                 <span style={{fontSize:"14px", fontWeight:'500'  , color:"rgb(47, 47, 47)"}} > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(priceShipping ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }   </span>
         </div>
      </div>
      }
        { priceShipping === 1 ?
          <div className='total'>
            <span>Total </span>
            <span className='total_item' > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  { Number(totalReview  ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }   </span>
         </div>
            :
         <div className='total'>
         <span>Total </span>
         <span className='total_item' >  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }   { Number(totalReview + priceShipping  ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }   </span>
      </div>
    }
        </div>
    </div>
    </div>
  )
}

export default Review
