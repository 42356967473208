const NewsLetterReducer = (state , action) => {


    switch(action.type) {

        case 'CREATE_NEWSLETTER_START': 
            return  {
                news  : [],
                isFetching : true,
                error : false, 
            }
           
        case 'CREATE_NEWSLETTER_SUCCESS': 
            return  {
                news  : action.payload,
                isFetching : false,
                error : false, 
            }

            case 'CREATE_NEWSLETTER_FAILURE': 
            return  {
                news  : [],
                isFetching : false,
                error : true, 
            }
    }

    return state

}

export default NewsLetterReducer