import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import Nav_top from '../Nav_top/Nav_top'
import './Categories.scss'
import { useState , useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import ListProduct from '../components/ListProduct/ListProduct'
import Paginations from '../Paginations/Paginations'
import Footer from '../components/Footer/Footer'

const Categories = ({openBag , setOpenBag , scroll , openShop , setOpenShop , rates , selectedCurrency}) => {

    const [color , setColor] = useState("All")
    const [colorArray , setColorArray] = useState([])
    const [selectedFilter , setSelectedFilter] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [highestValue , setHighestValue] = useState([]);
    const [searchProduct , setSearchProduct] = useState()
    const [valueOfOption , setValueOfOption] = useState("pertinence");
    const  searchValue =  window.location.pathname.split('/')[2]
    const [currentPage , setCurrentPage] = useState(1);
    const response = {data: {showlogo: 0}}
    const [availability, setAvailability] = useState(response.data.showlogo);

    // la logique pour pagination
    const nmbrMax = 12;
    const NbrPages = Math.ceil((searchProduct?.length + 1 )/ nmbrMax)
    const startIndex = (currentPage -1 ) * nmbrMax ; // 0*12 =0
    const finishIndex = (currentPage * nmbrMax ) // 1*12 = 12
    const ordredProduct = (searchProduct)?.slice(startIndex, finishIndex)



  const [value, setValue] =  useState([0 , 10000]) ;

  // on change a chaque fois l'url si le la valeur low chnage 
useEffect(() => {
  searchParams.set("low_price", value[0] );
  setSearchParams(searchParams);
 
}, [value[0]] , value[1] )

useEffect(() => {
  searchParams.set("high_price", value[1]);
  setSearchParams(searchParams);
}, [value[1]])







useEffect(() => {
  searchParams.set("availabilityB", availability );
  setSearchParams(searchParams);
}, [availability])

useEffect(() => {
  searchParams.set("sort_by", valueOfOption );
  setSearchParams(searchParams);
}, [valueOfOption])


useEffect(() => {
  axios.get(`https://caftannsapi-6.onrender.com/api/products/search/search_category?categoryS=${searchValue}&sort_by=${valueOfOption}&low_price=${value[0]}&high_price=${value[1]}&color=${colorArray.length === 0 ?  color : colorArray}&availabilityB=${availability}`)
  .then(response => {
      setSearchProduct(response.data)
  })
}, [searchValue , valueOfOption , value[0] , colorArray , value[1] , availability  ])

  
console.log(searchProduct , "product")




useEffect(() => {
  axios.get(`https://caftannsapi-6.onrender.com/api/products/search/search_category/price?categoryS=${searchValue}`)
  .then(response => {
      setHighestValue(response.data)
  })
}, [searchValue ])

const {pathname} = useLocation()

useEffect(() => {
  // "document.documentElement.scrollTo" is the magic for React Router Dom v6
  document.documentElement.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth" , // Optional if you want to skip the scrolling animation
  });
}, [pathname]);


console.log("searchValue" , )


  return (
    <div className='categories'>
              <div className='navbar' id="navbar"  >
                     <Nav_top  rates={rates} openBag={openBag} setOpenBag={setOpenBag} />
              </div>
             <div style={{maxWidth:"1500px" , marginLeft:"auto" , marginRight:"auto" , marginTop:"100px" , minHeight:"calc(100% - 480px)"}}>

                   <div className='categoryName_block' > <span className='title'> {searchValue} </span>    </div>
                   <Sidebar rates={rates} selectedCurrency={selectedCurrency} colorArray={colorArray} setColorArray={setColorArray} selectedFilter={selectedFilter}  setSelectedFilter={setSelectedFilter}  availability={availability} 
                   setAvailability={setAvailability} color={color} setColor ={setColor} searchParams={searchParams} 
                   setSearchParams={setSearchParams} searchValue={searchValue} scroll={scroll}
                     value={value} setValue={setValue} highestValue={highestValue} setHighestValue={highestValue} />

                    <ListProduct rates={rates} selectedCurrency={selectedCurrency} setOpenShop={setOpenShop} openBag={openBag} setOpenBag={setOpenBag} openShop={openShop} selectedFilter={selectedFilter} 
                                setSelectedFilter={setSelectedFilter} valueOfOption={valueOfOption} 
                                 setValueOfOption={setValueOfOption} searchProduct={searchProduct} ordredProduct={ordredProduct} />

                    <Paginations NbrPages={NbrPages} currentPage={currentPage} setCurrentPage={setCurrentPage} 
                                 searchProduct={searchProduct} setSearchProduct={setSearchProduct}/>


               </div>
               <Footer/>
    </div>
  )
}

export default Categories
