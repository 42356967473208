import React from 'react'
import './HomeSection.scss'
import 'aos/dist/aos.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {  useEffect} from 'react' 
import { useState } from 'react'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { BsHandbag} from "react-icons/bs"
import {BiShow} from "react-icons/bi"
import axios from 'axios'
import { Link } from 'react-router-dom'
import backgrounImage from "../../images/imgBG.JPG"
import caftan from "../../images/caftan.png"
import abaya from "../../images/abaya (2).jpg"
import kimono from "../../images/kimono.jpg"
import { BasketContext } from '../../Context/BasketContext/Basket'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'



var Carousel = require('react-responsive-carousel').Carousel;

const HomeSection = ({setOpenShop , openShop , scroll , openBag , setOpenBag , rates , selectedCurrency}) => {

    var myIndex = 0;
    carousel();

function carousel() {
  var i;
  var x = document.getElementsByClassName("slides");
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";  
  }
  myIndex++;
  if (myIndex > x.length) {myIndex = 0}   
   if(x[myIndex-1]) {
    x[myIndex-1].style.display = "block";  
   }
  setTimeout(carousel, 4000); // Change image every 2 seconds
}
const [products , setProducts] = useState([]);
const [searchProduct , setSearchProduct] = useState()



useEffect(() => {
    axios.get(`https://caftannsapi-6.onrender.com/api/products/search/newin`)
    .then(response => {
        setSearchProduct(response.data)
    })
  }, [])



let quantity = 0
let quatId = ""

products.map((product) => (
 product?.productDetails?.map((detail) => (
    detail.details?.map((item) => {
        item.variables?.map((variable) => {
            for(let i=0 ; i<product.length ; i++) {
              quantity +=  variable.quantity
              quatId = product._id
                return quantity
            }
        })
      })
 ))
))

// la fonction fais le calcule de toutes les tailles s'il sont a 0 ou il y a une quantity
const sommesQuantite = searchProduct?.map((produit) => {
    const details = produit.productDetails[0].details;
    const quantites = details?.map((detail) =>
      detail.variables.reduce((sum, variable) => sum + variable.quantity, 0)
    );
    const sommeQuantite = quantites.reduce((sum, quantite) => sum + quantite, 0);
    return sommeQuantite;
  });
  
const [idImageSelected , setIdImageSelected] = useState('')
const [idSelected , setIdSelected] = useState('')
const quantityValue = 1
const [quantityZero , setQuantityZero] = useState(false)
const [show , setShow] = useState(false)

const basket = useContext(BasketContext)


let array = []
let newSize = []
let newSizeId = []
var newQuantity = []


const {pathname} = useLocation()
useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth" , 
    });
  }, [pathname]);


  return (
    <div className="homeSection" style={scroll   > 120  ?{ marginTop:"220px"} : null}>
   

  <div className='slider_display'> 
     <img src={backgrounImage} alt={'Ns Caftan'}/>
    <div className='description'> 
        <span className='logo'> NS COLLECTION </span>
        <span className='title'> NEW COLLECTION </span>
        <Link to={'collections/nouveautes'} className='button_link'>  <span > je découvre </span> </Link>
    </div>
  </div>
  <div className='section_template'>
    <div className='section_template_container'>
        <Link to={`/collections/takchita`} className='grid_item'>
              <img  src={backgrounImage} alt='Takchita' />
              <div className='conatainer_title'>  <span> takchita </span> </div>
              
        </Link>
        <Link to={'/collections/caftan'} className='grid_item'>
             <img  src={caftan} alt='Caftan' />
                          <div className='conatainer_title'>  <span> caftan </span> </div>
        </Link>
        <Link to={'/collections/abaya'} className='grid_item'>
              <img  src={abaya}  alt='abaya'/>
                           <div className='conatainer_title'>  <span> abaya </span> </div>
        </Link>
        <Link to={'/collections/kimono'} className='grid_item'>
            <img  src={kimono}  alt='Kimono'/>
                         <div className='conatainer_title'>  <span> kimono </span> </div>
        </Link>
    </div>

    </div>
        <div className='new_collection'> 
             <div className='new_collection_container'> 

                <div className='new_collection_title'> 
                    <span>  LES NOUVEAUTÉS NS COLLECTION </span>
                </div>
                <div className='display_items'> 
                {searchProduct?.map((items) => (

                    <div key={items?._id} className='item_container'> 
                            <div  className='image_container'>
                                <div className='add_items_section' >
                                <Link to={{
                                  search: window.location.search
                              }}   preventScrollReset={true} state={{items}}  className="icons"  >  
                                     <li data-title="quickShop" onClick={() => setOpenShop(true)} > 
                                          <BiShow className='icon'/>  
                                      </li> 
                              
                              </Link>
                                    <div className='icons bagIcon'>
                                    <li data-title="Ajouter au panier"  > 
                                                  <BsHandbag  className="icon" 
                                                  onClick={() => { 
                                                      items.productDetails.map((productDetails , i) => {
                                                          if(i === 0) {
                                                              productDetails.details.map((details , i) => {
                                                              if(details._id === idImageSelected && idSelected === items._id ) {
                                                                  details.variables.map((variables , i) => {
                                                                      array.push(variables.quantity)
                                                                      for (let j = 0; j<array.length; j++) {
                                                                          if (array[j] > 0) {
                                                                                setOpenBag(true) ;
                                                                                newSize.push(variables.titre)
                                                                                newSizeId.push(variables._id)
                                                                                newQuantity.push(variables.quantity)
                                                                                      basket.addOneToBasket(
                                                                                          newSizeId[0],
                                                                                          details?._id,
                                                                                          quantityValue  ,
                                                                                          items.produitPrix,
                                                                                          details.url,
                                                                                          newSize[0],
                                                                                          newQuantity[0],
                                                                                          items.produitTitre,
                                                                                          details?.colorOption,
                                                                                          items._id
                                                                                      ) 
                                                                                  break;
                                                                          }else {
                                                                              setQuantityZero(true)
                                                                              setOpenBag(false) ;
                                                                          }
                                                                      } 
                                                                          
                                                                  })
                                                              } if(idSelected != items._id  && i== 0){
                                                                  details.variables.map((variables , i) => {
                                                                      array.push(variables.quantity)
                                                                      for (let j = 0; j<array.length; j++) {
                                                                          if (array[j] > 0) {
                                                                                setOpenBag(true) ;
                                                                                newSize.push(variables.titre)
                                                                                newSizeId.push(variables._id)
                                                                                newQuantity.push(variables.quantity)
                                                                                      basket.addOneToBasket(
                                                                                          newSizeId[0],
                                                                                          details?._id,
                                                                                          quantityValue ,
                                                                                          items.produitPrix,
                                                                                          details.url,
                                                                                          newSize[0],
                                                                                          newQuantity[0],
                                                                                          items.produitTitre,
                                                                                          details?.colorOption,
                                                                                          items._id
                                                                                      ) 
                                                                                  break;
                                                                          }
                                                                          else {
                                                                              setQuantityZero(true)
                                                                              setOpenBag(false) ;
                                                                          }
                                                                      } 
                                                                      
                                                                  })    
                                                              }
                                                              })
                                                          }
                                              
                                                      })
                                                  } 
                                              
                                              } 
                                          />
                                   </li> 
                                    </div>
                                </div>
                                <Link to= {`/collections/${items.produitTitre}/products/${items.produitTitre}?_id=${items._id}`} > 
                                     <img  alt={items.produitPrincipleImage} src={items.produitPrincipleImage}/>
                            </Link>
                              {items?.produitPrevPrix && <span className='sale'> {((100 - (items?.produitPrix * 100 )/ items?.produitPrevPrix)).toFixed(0)}%  </span>}
                          
                            
                              
                            </div>
                            <div className="infos_items">
                            <Link to= {`/collections/${items.produitTitre}/products/${items.produitTitre}?_id=${items._id}`} >   <span className='title'> {items?.produitTitre}  </span></Link>
                              <div>
                                 <span className='price'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(items?.produitPrix * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>
                                { items?.produitPrevPrix &&
                                    <span className="prevPrice"> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }{(items?.produitPrevPrix * rates ).toFixed(2)}  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>}
                               </div>
                                </div>
                    </div>
                ))}                </div>
             </div>
        </div>
    </div>
  )
}

export default HomeSection
