import { createContext } from "react";
import { useReducer } from "react";
import NewsLetterReducer from "./NewsletterReducer"


const INIT_STATE = {
    news : [],
    isFetching : false,
    error:false
}

export const NewsContext = createContext(INIT_STATE);

export const NewsContextProvider = ({children})  => {

    const [state , dispatch] = useReducer(NewsLetterReducer , INIT_STATE);

    
    return (
        <NewsContext.Provider
        value={{
            news : state?.news,
            isFetching: state?.isFetching,
            error: state?.error,
            dispatch ,
        }}
        >
        {children}
        </NewsContext.Provider>    

    );


}