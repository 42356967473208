import React from 'react'
import Footer from '../../components/Footer/Footer'
import Nav_top from '../../Nav_top/Nav_top'
import './Dilivery.scss'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Dilevery = ({openBag , setOpenBag , scroll ,  rates}) => {
    const {pathname} = useLocation()
    useEffect(() => {
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth" , 
        });
      }, [pathname]);


  return (
    <div className='dilevery'>
         <Nav_top rates={rates} openBag={openBag} setOpenBag={setOpenBag}  />
         <div className='size_wrapper'> 
            <div className='size_container'> 
            <span className='title'>  politique de livraison </span>
                <div className='size_left'> 
                    <div className='size_title_section'> 
                        <h4> GUIDE DE LIVRAISON  </h4>
                        <span>NsCollection s’engage à effectuer les livraisons sous 4 à 10 jours pour les articles en stock une fois votre commande prête à l'expedition.
                        Selon la disponibilité en stock, la livraison peut être très rapide. Nous vous tenons informés du statut de votre commande.
                        <br/>    <br/>  
                        En cas de rupture de stock, le délai moyen de production (en dehors de la collection mariée) est de 15 à 20 jours. 
                        <br/>    <br/>  
                        Pour les livraisons à l'international, NsCollection s'appuie sur ses partenaires DHL,  Fedex, ou chronopost. En fonction de 
                        votre commande et de votre pays de destination, la livraison est peut être payante. Délai de livraison selon votre commande..</span>
                    </div>
         
                   <div className='size_title_section'  > 
                      <h4 style={{fontSize:"16px"}}> LE SERVICE CLIENT À VOTRE DISPOSITION !  </h4>
                      <span className='long'>  N'hésitez pas à nous envoyer un message via whatsapp avec le lien ou la photo de votre commande pour avoir
                                               la confirmation du délai de livraison de votre panier au numéro suivant : +33771553049  </span>
                     <span>     Vous pouvez nous écrire 7j/7, 24h/24 :  <a className='contact' href="mailto: nsCollection.tls@gmail.com" > nsCollection.tls@gmail.com ! </a></span>
                    </div>
                </div>
            </div>
         </div>
         <Footer/>
    </div>
  )
}

export default Dilevery
