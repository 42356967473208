
import './Reviews.scss'
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import React, { useRef, useState , useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import axios from "axios";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";


const Reviews = () => {

    const [reviews , setReviews ] = useState()

    useEffect(() => {
        axios.get('https://caftannsapi-6.onrender.com/api/product/review')
        .then(response => 
            setReviews(response.data)
            )
            
    }, [])



  return (
    <div className='reviews'>
            <div className='reviews_container'>
                 <div className='page-width'> 
                    <div className='section-header'> 
                          <h2>les derniers avis de nos clientes</h2>
                    </div>
                    <div className='slider-swiper'>
                        <Swiper
                        loop={true}
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={"auto"}
                        coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows:false
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination , Autoplay ]}
                        className="mySwiper"
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                        
                    >
                                <SwiperSlide>
                                    <div className='raiting' > 
                                        <span>★★★★★</span>
                                    </div>
                                    <div className="text-description">
                                        <p> Coucou j’espère que tu verras ce message , j’adore ce que tu fais ,tout les articles sont de bonne qualités je recommande fort fort continue comme ça ma belle !!  </p>
                                    </div>
                                    <cite >Maria</cite>
                                    <div id="title" > Takchita AYA</div>
                                </SwiperSlide>
                            <SwiperSlide>
                            <div className='raiting' > 
                                <span>★★★★★</span>
                            </div>
                            <div className="text-description">
                                <p>  Coucou petit commentaire pour dire que ma mère a a trop aimé tes caftans ! </p>
                            </div>
                            <cite >Sihame</cite>
                            <div id="title" > Caftan ZINA</div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className='raiting' > 
                            <span>★★★★★</span>
                        </div>
                        <div className="text-description">
                            <p>Le Kimono est juste incroyable ! Il taille parfaitement et la qualité est irréprochable ! Mieux que ceux des autres brands ! </p>
                        </div>
                        <cite >Lina</cite>
                        <div id="title" > Kimono BLEU</div>
                    </SwiperSlide>
                   
                    </Swiper>

                    </div>
                 </div>

            </div>

    </div>
  )
}

export default Reviews
