import React, { useRef } from 'react'
import './Order.scss'
import { useContext } from 'react'
import { BasketContext } from '../Context/BasketContext/Basket'
import { AiFillQuestionCircle, AiOutlineLoading3Quarters } from 'react-icons/ai'
import { useState , useEffect } from 'react'
import axios from "axios"
import { BiChevronDown, BiChevronRight } from 'react-icons/bi'
import { PayPalScriptProvider, PayPalButtons ,  
    usePayPalScriptReducer } from "@paypal/react-paypal-js";
import TextField from "@material-ui/core/TextField"
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../Context/LoginContext/LoginActions'
import { LoginContext } from '../Context/LoginContext/LoginContext'
import Informations from './Informations/Informations'
import Shipping from './Shipping/Shipping'
import Payement from './Payement/Payement'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import {FiChevronDown, FiChevronUp, FiShoppingCart} from "react-icons/fi"
import Review from './Review/Review'

const Order = ({setOpenBag , rates , selectedCurrency}) => {

    // le basket avec les items dedans 
    const basket = useContext(BasketContext)

    const [products , setProducts] = useState({})
    const [country , setCountry] = useState()
    const [totalReview , setTotalReview] = useState()
    const globalId = []

        // mettre dans un tableau la quantité * price // pour faire le calcul total price
        const pricess = []
        basket.items.map((item) => {
    
            pricess.push(item.quantity*item.price)
            globalId.push(item.globalId)
        })
    
        let total = 0
        // total price
            for(let i = 0; i < pricess.length; i++) {
    
                total =( total + pricess[i])
            }
            total = total * rates
            

        // check if product exists or not 

        useEffect(() => {
            globalId.map((id) => {
                axios.get(`https://caftannsapi-6.onrender.com/api/products/${id}`)
                .then(response => 
                    setProducts(response.data)
                    )
                } )
                
        }, [])
        const idColor = []
        const variantId = []
        const basket1 = useContext(BasketContext).items
        basket1.map(item =>  idColor.push(item.idColor) )
        basket1.map(item =>  variantId.push(item.id) )
        basket1.map(item =>  variantId.push(item.id) )

  
       const [step , setStep] = useState(2)


        setOpenBag(false)



    useEffect( ()=>{
        const getcountry= async()=>{
            const rescountry= await fetch("https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json");
            const rescon= await rescountry.json();
            setCountry(await rescon);
        }
        getcountry();
    },[]);
    /// sort countries
    const unique = [...new Set(country?.map((val) => 
        val.country
    ) )]   
    unique.sort()


// get the user if exist
const [infos , setInfos] = useState()
const {dispatch } = useContext(LoginContext)

useEffect(() => {
 setInfos(JSON.parse(localStorage.getItem('login_user')))
}, [infos])

//***********  Order Infos         ******** */

const [infosOrder , setInfosOrder] = useState({})
const [infosFromLocal , setIonfosFromLocal] = useState({})
const [copyInfos , setCopyInfos] = useState([]) // mettre les infos de la carte dans copyInfos pour les afficher dans Review
const [idOrder , setIdOrder] = useState(null) ;

const handleChangeValues = (e) => {
    let value = e.target.value
    setInfosOrder({...infosOrder, [e.target.name]:value 

    })
}

    const [priceShipping , setPriceShipping] = useState(1)

    var TotalPrice = total + priceShipping

    // save infos with localStorage
    const [stepAction , setStepAction] = useState(false)
    //check phone number
    const [phoneNum , setPhoneNum] = useState(false)

    // load 

    const [load , setLoad] = useState(false)

    const saveInfos = () => {
        setLoad(true)

            setTimeout(() => {
                setStepAction(true)
                if(
                    (infosOrder.FName !== "") && ( infosOrder.FName !== undefined )&&
                ( infosOrder.LName !== "" )&&  (infosOrder.LName !== undefined) &&
                    (infosOrder.email !== "" )&&  (infosOrder.email !== undefined) &&
                ( infosOrder.adress !== "" )&& ( infosOrder.adress !== undefined )&&
                ( infosOrder.phoneNumber !== "") && ( infosOrder.phoneNumber !== undefined )&&
                ( infosOrder.city !== "") && ( infosOrder.city !== undefined) &&
                ( infosOrder.code_postal !== "") &&  (infosOrder.code_postal !== undefined )
                && (phoneNum)
                ){
                    setStep(step +1)
                    console.log('toute est ok')
                }
                else {
                    setStep(step)
                }
                localStorage.setItem('_ui', JSON.stringify(infosOrder))
                setLoad(false)
            }, 1000);
              
    }

    useEffect(() => {
        setIonfosFromLocal(JSON.parse(localStorage.getItem('_ui')))
      }, [])

      // name of shippier ex : colissimo

      const [nameShipper , setNameShipper] = useState('')

      // open and hide infos cart secton mobile

      const [openCart , setOpenCart] = useState(false)

   /************************************    Payement     * ********************************* */
 
   useEffect(() => {
    setTotalReview(total)
   }, [])


   console.log(infosOrder)

  return (
    <div className='order orderEvent'  >
        <div className='order_stepper'>
            <div className='stepper_container'>
            <Link to={"/"} >  <span className='logo'> NS Collection </span> </Link>
                 <div className='stepper'> 
                     <div className='stepper_items' >
                        <span className={ step > 1 ? 'done' : null} > Cart  </span>
                        <BiChevronRight className="icon"/>
                     </div>
                     <div className='stepper_items' >
                        <span onClick={() => setStep(step >= 2 ? 2 : step)} className={ step > 2 ? 'done' : step === 2 ? "active" : null}> Informations     </span>
                        <BiChevronRight className="icon"/>
                     </div>
                     <div className='stepper_items' >
                        <span  onClick={() => setStep( step>= 3 ? 3 : step)} className={ step > 3 ? 'done' : step === 3 ? "active" : null}> Shipping  </span>
                        <BiChevronRight className="icon"/>
                     </div>
                     <div className='stepper_items' >
                        <span  onClick={() => setStep( step>= 4 ? 4 : step)} className={ step > 4 ? 'done' : step === 4 ? "active" : null}> Payment  </span>
                        <BiChevronRight className="icon"/>
                     </div>
                     <div className='stepper_items' >
                        <span  onClick={() => setStep( step>= 5 ? 5 : step)} className={ step > 5 ? 'done' : step === 5 ? "active" : null}> Review  </span>
                        <BiChevronRight className="icon"/>
                     </div>
                 </div>
                    { step === 2 ?
                        <Informations  phoneNum={phoneNum} setPhoneNum={setPhoneNum} stepAction={stepAction} infosFromLocal={infosFromLocal} handleChangeValues={handleChangeValues} infosOrder={infosOrder} setInfosOrder={setInfosOrder} dispatch={dispatch} infos={infos} unique={unique} />
                     : step === 3 ?  
                        <Shipping total={total}  rates={rates} selectedCurrency={selectedCurrency} setNameShipper={setNameShipper} nameShipper={nameShipper} handleChangeValues={handleChangeValues} infosOrder={infosOrder} step={step}  setStep={setStep} setPriceShipping={setPriceShipping}  infos={infos}/>
                    :  step === 4 ?
                        <Payement selectedCurrency={selectedCurrency} idOrder={idOrder} setIdOrder={setIdOrder}  setCopyInfos={setCopyInfos} TotalPrice={TotalPrice} rates={rates} nameShipper={nameShipper}  infosOrder={infosOrder} step={step} setStep={setStep} priceShipping={priceShipping} infos={infos}/>
                        :  <Review idOrder={idOrder} totalReview={totalReview} copyInfos={copyInfos} TotalPrice={TotalPrice} rates={rates} selectedCurrency={selectedCurrency} nameShipper={nameShipper}  infosOrder={infosOrder} step={step} setStep={setStep} priceShipping={priceShipping} infos={infos}/>
                    }

                    { step !== 5 && // si il s'agit de la dernère étape on va pas aficher les buttons
                          ( 
                             <div className='buttons'>
                            <span className='back' onClick={(e) => {e.preventDefault();  setStep(step - 1)} }>
                                <HiOutlineChevronLeft className='icon'/>
                                {step  === 2  ? "Retour au panier" : step === 3 ? "Revenir aux informations" : "Revenir à l'expédition"}  
                            </span>
                            { step !== 4 ?
                                <span className='next' onClick={saveInfos}>  { !load ? "Continuer vers l'expidition" : <AiOutlineLoading3Quarters className='loader'/> }  </span>
                                : 
                                ""
                            //     <button  id="submit"  className='submit' >
                            //     <span id="button-text">
                            //         Pay now
                            //     </span>
                            // </button>

                            }

                            </div>
                           )
                    }
            </div>
        </div>

     
        <div className={!openCart ?  'order_infos_cart' : 'infos_cart_open'}>

            <div className='infos_container'>
               {  step !== 5 ? // si le step est n'egale pas 5 on va aficher le detail des robes sinon // on affiche le detail de la commande 
                         <>
                        <div className='carte_items'>
                            { basket.items.map((item) => (
                                <div className='cart__item-details'>
                                    <div className='cart__item-left'>
                                        <div className='cate_image' >
                                            <img src={item.url} alt="Selected Item"/>

                                            
                                        <span className='quantity'> {item.quantity}  </span>
                                        </div>
                                        <div className='cate_titles' >
                                            <p> {item.title} </p>
                                            <span> {item.color} / {item.size} </span>
                                        </div>
                                    </div>
                                    <div className='cart__item-right'>
                                    <span> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(item.quantity * item.price * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>

                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    {priceShipping === 1 ?
                                <div className='items_total'>
                                    <div className='section'>
                                            <span>Sous-total</span>
                                            <span className='subtotal' > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(total ).toFixed(2)}  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>
                                    </div>
                                    <div className='section'>
                                            <span>Expédition   <AiFillQuestionCircle id='icon'/> </span>
                                            <span style={{fontSize:"11px" , color:"rgb(122, 122, 122)"}} > Calculé à l'étape suivante </span>
                                    </div>
                                </div>
                        :
                                <div className='items_total'>
                                <div className='section'>
                                        <span>Sous-total</span>
                                        <span className='subtotal' > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(total ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>
                                </div>
                                <div className='section'>
                                        <span  >Livraison   <AiFillQuestionCircle id='icon'/> </span>
                                        <span style={{fontSize:"14px", fontWeight:'500'  , color:"rgb(47, 47, 47)"}} > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(priceShipping ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }   </span>
                                </div>
                                </div>
                    }
                    { priceShipping === 0 ?
                                <div className='total'>
                                    <span>Total </span>
                                    <span className='total_item' > {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  { Number(total  ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }   </span>
                                </div>
                        :
                                <div className='total'>
                                <span>Total </span>
                                <span className='total_item' >  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }   { Number(TotalPrice  ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }   </span>
                                </div>
                    }
                    </>
                    : 
                    <>
                                <div className="commande_header">
                                    <span className="num_cmd">COMMANDE #{idOrder} </span> 
                                </div> 
                                <h3> Merci pour votre achat !</h3> 
                                <p className='paragraph_thanks'> Bonjour<span className='name_user'> {infosOrder.FName}</span>, nous préparons l'envoi de votre commande. Nous vous informerons quand celle-ci aura été envoyée.  </p> 
                                <div className='links_after_commande'>
                                     <button> Afficher votre commande </button> ou <Link to={'/'} className='link'> Visitez notre boutique </Link>
                                </div>
                   </>
                }
            </div>
        </div>
        <div className='section_open_infosCart' onClick={() => setOpenCart(!openCart)} >
            <div className='container' onClick={() => setOpenCart(!openCart)} >
            <div className='adapter'>
                     <FiShoppingCart id='shop' />
                   <span>   {!openCart ? "Afficher " : "Fermer " } le sommaire de la commande  { !openCart ? <FiChevronDown/> : <FiChevronUp/>} </span>
            </div>
                   <p>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }   { Number(TotalPrice ).toFixed(2) }  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </p>
            </div>

        </div>

    </div>
  )
}

export default Order
