import { createContext } from "react";
import { useReducer , useEffect } from "react";
import ContactReducer from "./ContactReducer";

const INIT_STATE = {
    contact :   [],
    isFetching : false,
    error:false
}

export const ContactContext = createContext(INIT_STATE);

export const ContactContextProvider = ({children})  => {

    const [state , dispatch] = useReducer(ContactReducer , INIT_STATE);

  
    
    return (
        <ContactContext.Provider
        value={{
            contact : state?.contact,
            isFetching: state?.isFetching,
            error: state?.error,
            dispatch ,
        }}
        >
        {children}
        </ContactContext.Provider>    

    );


}