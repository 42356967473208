import React , {useState} from 'react'
import ReactCountryFlag from 'react-country-flag'
import './Navbar_media.scss'
import {TfiClose} from "react-icons/tfi"
import { IoIosArrowDown } from 'react-icons/io'
import { IoIosArrowUp } from 'react-icons/io'
import {SiInstagram} from "react-icons/si"
import {SiTiktok} from "react-icons/si"
import {SiSnapchat} from "react-icons/si"
import { Link, useNavigate } from 'react-router-dom'



const Navbar_media = ({navbarLeft , setNavbarLeft , selected , setCurrency , currency}) => {

    const [CategoryDown , setCategoryDown] = useState(false);
    const [collectionDown , setCollectionDown] = useState(false);
    const [faqDown , setFaqDown] = useState(false);


    
    const collection = () => {
        setCollectionDown(true)
        setCategoryDown(false)
        setFaqDown(false)
    }

    const category = () => {
        setCategoryDown(!CategoryDown)
        setCollectionDown(false)
        setFaqDown(false)
    }

    const faq = () => { 
        setFaqDown(!faqDown)
        setCategoryDown(false)
        setCollectionDown(false)

    }

    const handleCurrency = ()   => {
      setNavbarLeft(false)
        setTimeout(setCurrency(true) , 3000)
        document.getElementById('all_currencies').style.display = "block"  
    }

    const navigate = useNavigate()

    const naviagteAccount = () => {
        navigate('/account/login')
    }
  return (
    <>
        <div className='navbar_media--bg' onClick={() => setNavbarLeft(false)} >
        </div>
        <div className='navbar_media_container' style={{ visibility: !navbarLeft ? "hidden" : "visible",  transition: "all .2s"}} >
             <div className='content'>
                  <div className='top_bar'>
                          <div className='currency' onClick={handleCurrency}>
                                <ReactCountryFlag
                                countryCode={selected} svg id='flag' title={selected}
                                    />
                                    <ReactCountryFlag countryCode={selected === "FR" ? "EUR" : selected === "MA" ? "MAD" : selected === "US" ? "USD" : selected === "AE" ? "AED" : selected === "QA"  ?  "QAR" : selected === "GB" && "GBP"  } 
                                    id="nameFlag"/>
                          </div>
                          <TfiClose id="close_icon"  onClick={() => setNavbarLeft(false)}/>

                  </div>
                  <ul className='mobil-navigation'>
                        <li className='mobil-nav_item' style={{animation:" animate-acceuil-link 0.4s ease-in-out"  }} >
                                <Link to='/'  className='mobil-nav_link'> ACCEUIL </Link> 
                        </li>
                        <li className='mobil-nav_item' style={{animation:"animate-acceuil-link 0.5s ease-in-out"  }} >
                            <div className='mobil-nav_sublist' onClick={category}>
                                    <span  className='mobil-nav_link'> CATÉGORIE  </span>
                                    { !CategoryDown ?  
                                        <IoIosArrowDown id="icon_list"  />
                                        :
                                    <IoIosArrowUp id="icon_list" onClick={() => setCategoryDown(false)}  />
                                }
                            </div>
                           
                             <div className={CategoryDown ?  'Linklist' : 'Linklist-close'}>
                                    <Link onClick={() => setNavbarLeft(false)} to='/collections/caftan'  className='mobile-nav__link'> CAFTAN </Link>
                                    <Link onClick={() => setNavbarLeft(false)} to='/collections/jellaba'  className='mobile-nav__link'> JELLABA </Link>
                                    <Link onClick={() => setNavbarLeft(false)} to='/collections/takchita'  className='mobile-nav__link'> TAKCHITA </Link>
                                    <Link onClick={() => setNavbarLeft(false)} to='/collections/abaya'  className='mobile-nav__link'> ABAYA </Link>
                                    <Link onClick={() => setNavbarLeft(false)} to='/collections/gandoura'  className='mobile-nav__link'> GANDOURA </Link>
                                    <Link onClick={() => setNavbarLeft(false)} to='/collections/kimono'  className='mobile-nav__link'> KIMONO </Link>
                            </div>
  
                        </li>
                        <li className='mobil-nav_item'  style={{animation:"animate-acceuil-link 0.6s ease-in-out"  }} > <Link to='/collections/nouveautes'  className='mobil-nav_link'> NOUVEAUTÉS </Link> </li>
                        {/* <li className='mobil-nav_item'  style={{animation:"animate-acceuil-link 0.7s ease-in-out"  }}>  <div className='mobil-nav_sublist'>
                                    <a href=''  className='mobil-nav_link'> COLLECTIONS  </a>
                                    { !collectionDown ?  
                                        <IoIosArrowDown id="icon_list" 
                                        onClick={collection}
                                        />
                                        :
                                     <IoIosArrowUp id="icon_list" onClick={() => setCollectionDown(false)}  />
                                }
                            </div>
                        
                            <div className={collectionDown ?  'Linklist' : 'Linklist-close'}>
                                    <a href='/collections/caftan-marocain'  className='mobile-nav__link'> CAFTAN MAROCAIN </a>
                                    <a href='/collections/mariage-marocain'  className='mobile-nav__link'> MARIAGE MAROCAIN </a>
                                    <a href='/collections/abaya-marocaine'  className='mobile-nav__link'> ABAYA MAROCAINE </a>
                                
                            </div> 
                    </li> */}
                        <li className='mobil-nav_item' style={{animation:"animate-acceuil-link 0.8s ease-in-out"  }} > <Link to='/collections/solde'  className='mobil-nav_link'> SALE </Link> </li>
                        <li className='mobil-nav_item'  style={{animation:"animate-acceuil-link 0.9s ease-in-out"  }} >
                        <div className='mobil-nav_sublist'>
                        <span className='mobil-nav_link'   onClick={faq}> FAQ  </span>
                        { !faqDown ?  
                            <IoIosArrowDown id="icon_list" 
                          
                            />
                            :
                         <IoIosArrowUp id="icon_list" onClick={() => setFaqDown(false)}  />
                    }
                </div>
                <div className={faqDown ?  'Linklist' : 'Linklist-close'}>
                        <Link to='' onClick={() => setNavbarLeft(false)} className='mobile-nav__link'> LA MAISON </Link>
                        <Link to='' onClick={() => setNavbarLeft(false)} className='mobile-nav__link'> INSPIRATION </Link>
                        <Link to='/pages/faq' onClick={() => setNavbarLeft(false)} className='mobile-nav__link'> FAQ </Link>
                </div> 
                        </li>
                        <li className='mobil-nav_item' style={{animation:"animate-acceuil-link 1s ease-in-out"  }} > <Link to='/pages/delais-de-livraison'  className='mobil-nav_link'> DELAI DE LIVRAISON </Link> </li>
                        <li className='mobil-nav_item' style={{animation:"animate-acceuil-link 1.2s ease-in-out"  }} > <Link to='/pages/guide-des-tailles'  className='mobil-nav_link'> GUIDE DE TAILLES </Link> </li>
                        <li className='mobil-nav_item' style={{animation:"animate-acceuil-link 1.4s ease-in-out"  }} > <Link to='/pages/nous-conntacter'  className='mobil-nav_link'> NOUS CONTACTER                        </Link> </li>
                        <li className='mobil-nav_item' style={{animation:"animate-acceuil-link 1.6s ease-in-out"  }} > <Link to='' onClick={naviagteAccount} className='mobil-nav_link' style={{fontSize:"14px"}}> Se connecter                        </Link> </li>
                        <div className='social-media-links' style={{animation:"animate-acceuil-link 1.8s ease-in-out"  }}>
                             <a href=''> <SiInstagram  id="icon" /> </a>
                             <a href=''> <SiTiktok  id="icon" /> </a>
                             <a href=''> <SiSnapchat  id="icon" /> </a>
                        </div>
                  </ul>

             </div>
            
        </div>
    </>
  )
}

export default Navbar_media
