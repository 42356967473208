import { useLocation  } from 'react-router-dom'
import './QuickShop.scss'
import React, { useRef, useState , useEffect, useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { TfiClose } from 'react-icons/tfi';
import { GoPrimitiveDot, GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import {Link} from "react-router-dom"
import { BasketContext } from '../../Context/BasketContext/Basket';
import {BsFillBagPlusFill} from "react-icons/bs"
import { BiLoaderAlt } from 'react-icons/bi';

var Carousel = require('react-responsive-carousel').Carousel;


const QuickShop = ({openShop , setOpenShop , setOpenBag , openBag , rates , selectedCurrency}) => {


    const location = useLocation().state

    var myIndex = 0;
    carousel();

function carousel() {
  var i;
  var x = document.getElementsByClassName("slides");
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";  
  }
  myIndex++;
  if (myIndex > x.length) {myIndex = 0}   
   if(x[myIndex-1]) {
    x[myIndex-1].style.display = "block";  
   }
  setTimeout(carousel, 3000); // Change image every 2 seconds
}



const [idColorSelected , setIdColorSelected] = useState("")
const [idSizeSelected , setIdSizeSelected] = useState("")
const [quantityValue , setQuantityValue] = useState(1)
const [imageSelected , setImageSelected] = useState('')
const [sizeTitle , setSizeTitle] = useState('')
const [colorOption , setColorOption] = useState('')
const [msgError , setMsgError] = useState(false)
const basket = useContext(BasketContext)
const [load , setLoad] = useState(false)
const path = useLocation()

    const hidePage = (e) => {
        e.stopPropagation()
        setIdColorSelected('')
        setIdSizeSelected('')
        setOpenShop(false)
        setMsgError(false)

    }
    const petsistPage = (e) => {
        e.stopPropagation()
        setOpenShop(true)
    }

  

    useEffect(() => {
        if(location !== undefined) {
            location?.items?.productDetails?.map((item) => {
                item.details.map((details , i) => {
                 
                       // si la couleur n'est pas séléctioner et lindicateur === 0
                       if(i== 0 && idColorSelected !== details._id ) {
                        setIdColorSelected(details._id) // on donne la premiere couleur au variable IdColorSelected
                        setImageSelected(details.url) // on donne la variable ImageSelected)
                        setColorOption(details.colorOption) // on donne la variable ColorOption)
                        
                        details.variables.map((variables , i) => {
                        if(idSizeSelected !== variables._id  && i==0) { // et sizeId n'est pas séléctionner on donne le premier trouver 
                            setIdSizeSelected(variables._id) 
                            setSizeTitle(variables.titre)
                        }
                    })
                    }
        
                })
        
            })
        }

    }, [location?.items || idColorSelected])

  

    useEffect(() => {
        location?.items?.productDetails?.map((item) => {
            item.details.map((details , i) => {
             
                /// si la couleur est selectionner
                if(idColorSelected === details._id) { /// si la couleur egale la idCouleur 
                    details.variables.map((variables , i) => {

                        if(idSizeSelected === variables._id ) {
                            console.log("oui oui")
                        }
                        if(idSizeSelected === "" ) { // et sizeId n'est pas séléctionner on donne le premier trouver 
                              if(i==0) {
                                setIdSizeSelected(variables._id)
                                  setSizeTitle(variables.titre)
                                
                              }

                        }
                     })
                }
 
                })
            })
    }, [idColorSelected])


let quantityMax  = 0 ;
    
   location?.items?.productDetails?.map((item) => {
        item?.details?.map((details) =>{
           if (details?._id === idColorSelected)  {
            details?.variables?.map((variant) => {
                if(variant?._id === idSizeSelected) {
                    quantityMax = variant.quantity
                }
            })
           }
        })

   }) 

 
  useEffect(() => {
    path.pathname.split("/")[1] === "checkouts" && setOpenShop(false) 
  }, [path])
/*
   
*/
  return (
    <div onClick={hidePage} className={ !openShop ? 'quikShop_page' : 'quickShop_open'}>
    <div style={load ? {boxShadow:"inset 400px 0 0 0 rgb(145 104 15 / 92%)"} : {backgroundColor:"#b59148"}}
    
    onClick={(e) =>
        {  e.preventDefault(); 
           e.stopPropagation()
                setLoad(true);
            setTimeout(() => {
                setLoad(false);
                }, 2000);
            /// si la quantité est égale a 0 in affiche le message d'erreur
          // sinon on rajoute le produit
           setTimeout(() => { 
                    (  (quantityMax > 0)  ?   setOpenBag(true) : setOpenBag(false)) ;   
                    ( (quantityMax > 0)  ?   setOpenShop(false) : setOpenShop(true)) ; 
                    if(quantityMax === 0 ) {
                        setMsgError(true);
                    
                        setTimeout(() => {
                            setMsgError(false);
                            }, 2000);
                    } ;
                    if( quantityMax > 0) 
                        basket.addOneToBasket(
                            idSizeSelected , 
                            idColorSelected,
                            quantityValue,
                            location?.items?.produitPrix,
                            imageSelected,
                            sizeTitle,
                            quantityMax ,
                            location?.items?.produitTitre,
                            colorOption,
                            location?.items?._id
                        )
           }, 2000); 
                    
    }
    }    className='bag_addTo'>
                <BsFillBagPlusFill className={ !load ? 'bag_icon' : 'bag_icon_load'} />
    </div>
      <span className='messageError' style={msgError ? {animation:"animateError 3s"} : null} > <span>   La taille de cette couleur est épuisé </span> </span>
        <div  onClick={petsistPage} className={openShop ? 'quikShop_containerOpen' : 'quikShop_container'} >
           <TfiClose onClick={hidePage}  className='closeMedia'/>
            <div  className='quikShop_Images'>
                    <Carousel    preventMovementUntilSwipeScrollTolerance={true} and swipeScrollTolerance={50} useKeyboardArrows={true}  showStatus={false} showArrows={false} autoPlay={true} stopOnHover swipeable infiniteLoop style={{width:"100%"}} >
                            {  location?.items?.productDetails?.map((item) => (
                                item.details.map((pro) => (
                                pro.images.map((image) => (
                                        <img src={image} alt={`${location?.items?.produitCategorie}  ${location?.items?.produitTitre}`} /> 
                                ))
                                ))
                                ))}
                </Carousel>
            </div>
            <div className='quickShop_infos'>
                <div className='infos_header'>
                       <TfiClose onClick={hidePage} className="close" />
                </div>
            
                <div className='info_conainer'>
                    <div className='info_single_meta'>
                        <div className='info_title'>
                             <span> {location?.items?.produitTitre} </span>
                        </div>
                        <div className='info_price'>
                            <span className='actual'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(location?.items?.produitPrix  * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>
                          { location?.items?.produitPrevPrix &&
                            <span className='prev'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(location?.items?.produitPrevPrix  * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>
                             }
                        </div>
                    </div>
                    <form className='form'>
                        <div className=' selctor-wrapper_colors'>
                                <label>Couleur </label>
                              
                                        <fieldset class="fieldset">
                                        { location?.items?.productDetails?.map((detail) => (
                                            detail?.details?.map((product , i) => (
                                                 
                                                        <div onClick={() => { 
                                                            setIdColorSelected(product._id)
                                                            if(product._id !== idColorSelected) {
                                                                setIdSizeSelected('')// id de size
                                                            }
                                                            setQuantityValue(1)
                                                            setImageSelected(product.url)
                                                            setSizeTitle('')
                                                            setColorOption(product.colorOption)
                                                        }} class="color-option_wrapper">
                                                            <li data-title={product?.colorOption}>
                                                                <img  className={idColorSelected === product._id ? "selectedImage"   : null }  src={product?.url} alt={`${location?.items?.produitCategorie}  ${location?.items?.produitTitre}`}/>
                                                            </li>
                                                        </div>
                                                    ))
                                                ))}
                                        </fieldset>   
                        </div>
                        <div class="selector-wrapper_sizes">
                             <label> Taille </label>
                             <div class="size-options_wrapper">
                             { location?.items?.productDetails?.map((detail) => (
                                detail?.details?.map((product , i) => (
                                  product._id === idColorSelected ?
                                    product.variables.map((variant, i) => (
                                        <span   style={variant.quantity === 0 ? { textDecoration:"line-through" , textDecorationColor:"rgb(136, 101, 36) " } : null}
                                        onClick={() => {
                                             setQuantityValue(1)  ; 
                                             setIdSizeSelected(variant._id) 
                                             setSizeTitle(variant.titre)
                                            }}
                                         key={i} className={variant._id === idSizeSelected ? "sizeSelected"   : null  } > {variant?.titre} </span>
                                    ))
                                 : null
                                ))
                        ))}
                             </div>
                        </div>
                        <div class="product-form_quantity">
                                <div class="quantity">
                                    <input value={quantityValue} />
                                    <div className="down_up" > 
                                            <GoTriangleUp className='icon' 
                                                onClick={() =>  setQuantityValue(  (quantityValue  < quantityMax )  ?    ( quantityValue + 1 ) : quantityMax ) }
                                            /> 
                                            <GoTriangleDown className='icon' 
                                                onClick={() =>  setQuantityValue( (quantityValue  > 1 ) ? quantityValue -  1 : 1  )   }
                                            />
                                    </div>
                            </div>
                            <div className='addTo_cart'>  
                                    {  (quantityMax > 0 )  ?
                                    <button className="add" 
                                     onClick={(e) =>
                                      {  
                                        e.preventDefault();

                        setTimeout(() => {
                                         setOpenBag(true);
                                        basket.addOneToBasket(
                                            idSizeSelected , 
                                            idColorSelected,
                                            quantityValue,
                                            location?.items?.produitPrix,
                                            imageSelected,
                                            sizeTitle,
                                            quantityMax ,
                                            location?.items?.produitTitre,
                                            colorOption,
                                            location?.items?._id
                                        )
                                    },2000)
                                }
                                    }   
                                        
                                    > AJOUTER AU PANIER </button>
                                    :
                                    <button className="epuisé" onClick={(e) =>  e.preventDefault() } > épuisé </button>
                                    }
                            </div>
                        
                        </div>
                        <Link to= {`/collections/${location?.items?.produitCategorie}/products/${location?.items?.produitTitre}?_id=${location?.items?._id}`} >
                        <span onClick={hidePage} className='goTo'>Aller à la page produit  </span>
                  </Link>
                    </form>
                </div>
            </div>

        </div>
    </div>
  )
}

export default QuickShop
