import React from 'react'
import Footer from '../components/Footer/Footer'
import Related from '../components/Related/Related'
import Reviews from '../components/Reviews/Reviews'
import Nav_top from '../Nav_top/Nav_top'
import './OneProduct.scss'
import Singlproduct from './Singlproduct/Singlproduct'


const OneProduct = ({scroll , openBag , setOpenBag , setOpenShop , rates , selectedCurrency}) => {
  return (
    <div className='page-width_singleProduct' >
            <Nav_top  rates={rates} openBag={openBag} setOpenBag={setOpenBag} />
            <Singlproduct openBag={openBag} setOpenBag={setOpenBag} rates={rates} selectedCurrency={selectedCurrency} />
            <Reviews/>
            <Related  setOpenShop={setOpenShop} openBag={openBag} setOpenBag={setOpenBag} />
            <Footer/>
    </div>
  )
}

export default OneProduct
