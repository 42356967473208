const ReviewReducer = (state , action) => {


    switch(action.type) {

        case 'CREATE_REVIEW_START': 
            return  {
                review  : [],
                isFetching : true,
                error : false, 
            }
           
        case 'CREATE_REVIEW_SUCCESS': 
            return  {
                review  : action.payload,
                isFetching : false,
                error : false, 
            }

            case 'CREATE_REVIEW_FAILURE': 
            return  {
                review  : [],
                isFetching : false,
                error : true, 
            }

    }

    return state

}

export default ReviewReducer