import React from 'react'
import './Newsletter.scss'
import kimono from "../../images/kimono.jpg"
import { IoMdClose} from "react-icons/io"
import {MdOutlineArrowRightAlt} from "react-icons/md"
import {useState , useEffect} from "react"
import { NewsContext } from '../../Context/NewsletterContext/NewsletterContext'
import axios from "axios"
import { CreateNewsLetterFailure, CreateNewsLetterStart, CreateNewsLetterSuccess } from '../../Context/NewsletterContext/NewsletterActions'
import { useContext } from 'react'

const Newsletter = () => {

    const [close , setClose] = useState(true)
    const [error , setError] = useState(false)
    const [email , setEmail] = useState()
    const [load , setLoad] = useState(false)
    const [success , setSuccess] = useState(false)
    const [check , setCheck] = useState(false)


useEffect(() => {
    setTimeout(() => {
        setClose(false)
},5000)
},[])
  
  
    const hidePage = (e) => {
        e.stopPropagation()
        setClose(true)
    }
    const openPage = (e) => {
        e.stopPropagation()
        setClose(false)
    }

    const handleChange = (e) => {
        let value = e.target.value
        setEmail({[e.target.name]:value})
    }


    const {dispatch} = useContext(NewsContext)

    
    const createNewsLetter = async (news , dispatch) => { 
    
        dispatch(CreateNewsLetterStart());
        setLoad(true)

        try {
          setError(false)
                setLoad(true)
              const res = await axios.post("https://caftannsapi-6.onrender.com/api/newsletter", news);
              dispatch(CreateNewsLetterSuccess(res.data))
              console.log(res.data)
            setError(false)
            setTimeout(() => {
                 setSuccess(true)
                setLoad(false)
                    localStorage.setItem('newsLetter', true)
            }, 2000);
            
    
        }
        catch(err) {
          setError(true)
          setTimeout(() => {
           setLoad(false)
       }, 2000);
          setSuccess(false)
          dispatch(CreateNewsLetterFailure())
        }
    }


    const submitNews = () => {

        createNewsLetter (email , dispatch)
        setCheck(true)
    }

    const [infos , setInfos] = useState(false)
    useEffect(() => {
        setInfos(JSON.parse(localStorage.getItem('newsLetter')))
    }, [])
    
    console.log(infos , "infos")
  return (
!infos &&
    <div className={!close ? 'newsLetter' : 'newsLetterOpen'} onClick={hidePage} >
        <div className='newsLetter_container' onClick={openPage}>
                <img src={kimono} alt='kimono'/>
                  <div className='newsLetter_content'>
                     <div className='section_close'>   
                         <IoMdClose style={{cursor:"pointer"}} onClick={hidePage}/> 
                      </div>
                      <span className='title'>INSCRIS-TOI À LA NEWSLETTER </span>
                      <span className='description'>En t'inscrivant à la newsletter, tu acceptes de recevoir 
                            nos offres, nouveautés et promotions. Rejoins-moi !
                      </span>
                      {!success  ?
                      <div className='input_container'>
                             <input onChange={handleChange} type="email" name="Email" placeholder='Inscrivez-vous à notre infolettre'/>
                              <MdOutlineArrowRightAlt type='button' onClick={submitNews} className={!load ? 'icon' : "iconLoad"}/>
                      </div>
                      : 
                      <div className='success'> 
                             Merci de vous être inscrit

                      </div>
                    }
                      <p>  merci, les filles !  </p>
                  

                  </div>
        </div>
    </div>
                
  )
}

export default Newsletter
