import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { CreateOrderFailure, CreateOrderStart, CreateOrderSuccess } from "./Context/OrderContext/OrderActions";
import { OrderContext } from "./Context/OrderContext/OrderContext";
import axios from "axios";
import './CheckoutForm/CheckoutForm.scss'
import { BasketContext } from "./Context/BasketContext/Basket";

export const handleSubmit = async (stripe, elements, setIsLoading, setMessage ) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
  
    setIsLoading(true);
  
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000",
      },
    });
  
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
  
    setIsLoading(false);
  };
  
 


const CheckoutForm = ({ email1 , setStep  , step  , infosOrder , setCopyInfos , TotalPrice , priceShipping  , idOrder , setIdOrder , selectedCurrency}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderInfos, setOrderInfos] = useState({
    products: [], // Initialisez avec un tableau vide
    totalAmount: 0, // Initialisez avec la valeur par défaut
    shippingAddress: '', // Initialisez avec une chaîne vide
    status: 'pending', // Initialisez avec la valeur par défaut
    payment_intent: '', // Initialisez avec une chaîne vide
  });


  useEffect(() => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    let randomId = '';
    
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
    
    setIdOrder(randomId);
  }, [step]);

  console.log(idOrder);
 

  useEffect(() => {
    const basket = JSON.parse(localStorage.getItem('basket'));
    const user = JSON.parse(localStorage.getItem('login_user'));
    console.log(basket , "basketO")
  
    const mappedProducts = basket.map((product) => ({
 
      productId: product.id,
      productName: product.title,
      quantity: product.quantity,
      priceOfProduct : product.price,
      size:product.size , 
    
  }));



  const { _id, FName, LName, Email } = user?.others ?? {}; // provide an empty object as default


  
  setOrderInfos((prevOrderInfos) => ({
    ...prevOrderInfos,
    products: mappedProducts,
    idClient: _id ?? null,
    idOrder: idOrder,
    emailClient : infosOrder.email ,
    totalAmount:( TotalPrice + priceShipping), // Remplacez cette valeur par la valeur réelle
    shippingAddress: infosOrder.adress+"," + infosOrder.city+"," + infosOrder.country, // Remplacez cette valeur par la valeur réelle
    currency:selectedCurrency

  }));

  }, [step , idOrder])


  console.log(orderInfos , "orderInfos")

  const createOrder = async (  order  , dispatch) =>{

    dispatch(CreateOrderStart());
    try {
        const res = await axios.post("https://caftannsapi-6.onrender.com/api/orders", order);
        dispatch(CreateOrderSuccess(res.data))

    }
    catch(err) {
        dispatch(CreateOrderFailure())
    }



  }
  const {dispatch } = useContext(OrderContext)

  useEffect(() => {
    setCopyInfos(basket.items); 
  }, [])

const submitOrder = () => {

  createOrder(orderInfos , dispatch)
}
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

    // update products after payment
    var products = JSON.parse(localStorage.getItem('basket'));

    const updateProducts = async function() {
      try {
        const response = await axios.put('https://caftannsapi-6.onrender.com/api/products/update', { products });
        console.log(response.data.message);
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }

    // baskte to clear after a successful update
    const basket = useContext(BasketContext)


    // handleSubmit 
  const handleSubmitLocal = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    setIsLoading(true);
  
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Paramètres de confirmation supplémentaires si nécessaire
      },
      redirect: 'if_required'
    });
  
    if (error && (error.type === "card_error" || error.type === "validation_error")) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setStep(5)
      submitOrder()
      await     updateProducts()
     // Copier les informations du panier dans copyInfos
      basket.clearBasket()
      setMessage("Payment successfully completed!");
      // Logique supplémentaire à exécuter lorsque le paiement est effectué
    } else {
      setMessage("An unexpected error occurred.");
      // Logique pour d'autres scénarios de paiement
    }
  
    setIsLoading(false);
  };
  

  const paymentElementOptions = {
    layout: "tabs"
  }


  

  return (
    <div>
      <form id="payment-form" onSubmit={handleSubmitLocal} className="form1">
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {message && <div id="payment-message">{message}</div>}
      </form>
    
      <button className="button" id="submit" onClick={handleSubmitLocal}>
        <span id="button-text">
          COMMANDER
        </span>
      </button>
    </div>
  );
}

export default CheckoutForm;
