import { createContext } from "react";
import { useReducer , useEffect } from "react";
import OrderReducer from "./OrderReducer";

const INIT_STATE = {
    order :   [],
    isFetching : false,
    error:false
}

export const OrderContext = createContext(INIT_STATE);

export const OrderContextProvider = ({children})  => {

    const [state , dispatch] = useReducer(OrderReducer , INIT_STATE);

  
    
    return (
        <OrderContext.Provider
        value={{
            order : state?.order,
            isFetching: state?.isFetching,
            error: state?.error,
            dispatch ,
        }}
        >
        {children}
        </OrderContext.Provider>    

    );


}