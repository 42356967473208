

export const CreateReviewStart = () => ({

    type : "CREATE_REVIEW_START",

})
export const CreateReviewSuccess = (review) => ({

    type : "CREATE_REVIEW_SUCCESS",
    payload : review

})
export const CreateReviewFailure = () => ({

    type : "CREATE_REVIEW_FAILURE",

})