import { createContext , useState , useEffect , useContext } from "react"; 


 export const  BasketContext = createContext({
    items : [],
    getProductQuantity: () => {    },
    addOneToBasket:() => {},
    removeOneFromBasket: () => {},
    deleteFormaBasket:() => {},
    getTotalCost:() => {},
    clearBasket:() => {},
})

export function BasketProvider({children}) {


    const InitBag = []

    const getInitialState = () => {
        const items = localStorage.getItem('basket');
        return items ? JSON.parse(items) : InitBag
    }
    const [basketProducts , setBasketProducts] = useState(getInitialState)
   

    useEffect(() => {
        localStorage.setItem('basket', JSON.stringify(basketProducts))
    }, [basketProducts])


    // on cherche le produit par son ID /// si on le trouve on modifie la quantité // sinon 0
    function getProductQuantity(id){
        const quantity =   basketProducts.find(product => product?.id === id)?.quantity

        if(quantity === undefined) {
            return 0;
        }
        return quantity ;
    }

    function addOneToBasket(id , idColor , quantity1 , price , url , size  , quantityMax , title , color , globalId)   {
        const quantity = getProductQuantity(id);

        if (quantity === 0) { // product is not in cart
            setBasketProducts([...basketProducts,
            {   
                id: id,
                idColor:idColor,
                quantity: quantity1 ,
                price : price,
                url : url,
                size: size,
                quantityMax : quantityMax,
                title : title,
                color:color,
                globalId:globalId
            }

                ]
                
            )

            
        } else { // product is in cart
            // [ { id: 1 , quantity: 3 }, { id: 2, quantity: 1 } ]    add to product id of 2
            setBasketProducts(
                basketProducts.map(
                    product =>
                    product.id === id                                // if condition
                    ? { ...product, quantity: product.quantity + quantity1 } // if statement is true
                    : product                                     // if statement is false
                )
            )

        }
 
    }

  /*  function getTotalCost() {
        let totalCost = 0
        basketProducts.map((basketItem) => {
            const productData = getProductDtata
        })
    }
    */


    function removeOneFromBasket(id ) {
        const quantity = getProductQuantity(id);

        if(quantity === 1) {
            deleteFormaBasket(id)
        }else {
            setBasketProducts(
                basketProducts.map(product => product.id === id
                    ? {... product , quantity: product.quantity - 1}
                    :product
                )

            )
        }
    }
    function deleteFormaBasket(id) {
        setBasketProducts(
            basketProducts  =>
            basketProducts.filter(currentProduct => {
                return currentProduct.id != id;
            } )
        )
    }
    function clearBasket() {
        setBasketProducts([]);
      }
    

    const contextValue = {
        items : basketProducts,
        getProductQuantity,
        addOneToBasket,
        removeOneFromBasket,
        deleteFormaBasket,
        clearBasket
     //   getTotalCost

    }






  return  (
        <BasketContext.Provider value={contextValue}>   
                    {children}
        </BasketContext.Provider>    

)

}


export default BasketProvider