
import './App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from "./HomePage/HomePage"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Search from "./Search/Search"
import { useState } from 'react'
import OneProduct from './oneProduct/OneProduct';
import Bag from './components/Bag/Bag';
import QuickShop from './components/QuickShop/QuickShop';
import Categories from './Catogories/Categories';
import Register from './Account/Register/Register';
import Login from './Account/Login/Login';
import Account from './Account/Account/Account';
import New from './New/New';
import Sale from './Sale/Sale';
import SizeGuide from './pages/sizeGuide/SizeGuide';
import Dilevery from './pages/Dilevery/Dilevery';
import Contact from "./pages/Contact/Contact"
import CVG from './pages/CVG/CVG';
import MLegal from './pages/MLegal/MLegal';
import Return from './pages/Return/Return';
import Privacy from './pages/Privacy/Privacy';
import Faq from './pages/Faq/Faq';
import Order from './Order/Order';
import Newsletter from './components/Newsletter/Newsletter';
import Pay from './Pay';
import { MissingRoute } from './MissingRoute/MissingRoute';


function App() {

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    AOS.init();
  }, [])

  window.onscroll = () => {
    /// si la page est en haut donc c faux sinon true 
    setScroll(window.pageYOffset)
  }

  if (document.getElementById('bag_page')?.className === "bag_page open") {

    document.querySelector('.app').style.overflow = "hidden"

  }

  const [openBag, setOpenBag] = useState(false);
  const [openShop, setOpenShop] = useState(false);
  const [rates, setRates] = useState()
  const [checkRates, setCheckRates] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState("EUR")
  const [selected, setSelected] = useState("FR")


  /// fetch convert currency 
  useEffect(() => {
    const currencyRates = {
      EUR: 1,
      USD: 1.0794022,
      AED: 3.9647494,
      QAR: 3.9296631,
      GBP: 0.84536756,
      MAD: 10.722881
    };
  
    setRates(currencyRates[selectedCurrency] || 1); 
  }, [selectedCurrency]);


  console.log("'selectedCurrency" , selectedCurrency)

  return (
    <BrowserRouter>
      <div className={openBag === false ? "app" : 'app_scrolled'}>
        <>
          <Routes>
            <Route path="/" element={<HomePage  checkRates={checkRates} selected={selected} setSelected={setSelected} rates={rates} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} openBag={openBag} openShop={openShop} setOpenShop={setOpenShop} scroll={scroll} setOpenBag={setOpenBag} />} />
            <Route path="/search" element={<Search rates={rates} selectedCurrency={selectedCurrency} setOpenShop={setOpenShop} openShop={openShop} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/collections/:category/products/:title" element={<OneProduct rates={rates} selectedCurrency={selectedCurrency} setOpenShop={setOpenShop} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/collections/:category" element={<Categories rates={rates} selectedCurrency={selectedCurrency} openShop={openShop} setOpenShop={setOpenShop} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/collections/nouveautes" element={<New rates={rates} selectedCurrency={selectedCurrency} openShop={openShop} setOpenShop={setOpenShop} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/collections/solde" element={<Sale rates={rates} selectedCurrency={selectedCurrency} openShop={openShop} setOpenShop={setOpenShop} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/account/register" element={<Register rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/account/login" element={<Login rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/account" element={<Account rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/guide-des-tailles" element={<SizeGuide  rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/delais-de-livraison" element={<Dilevery  rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/nous-conntacter" element={<Contact rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/CGV" element={<CVG rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/mentions-legales" element={<MLegal rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/retours-echanges" element={<Return rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/confidentialite" element={<Privacy rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/pages/faq" element={<Faq rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} />} />
            <Route path="/checkouts/:id" element={<Order rates={rates} selectedCurrency={selectedCurrency} setOpenBag={setOpenBag} />} />
            <Route path="/test" element={<Pay />} />
            <Route path="*" element={<MissingRoute />} /> 
          </Routes>
          <Bag openBag={openBag} setOpenBag={setOpenBag} rates={rates} selectedCurrency={selectedCurrency} />
          <QuickShop rates={rates} selectedCurrency={selectedCurrency} openBag={openBag} setOpenBag={setOpenBag} openShop={openShop} setOpenShop={setOpenShop} />
          <Newsletter />
          {/* <Popups/>   pop up for suggestion */}
        </>
      </div>
    </BrowserRouter>
  );
}

export default App;
