

export const CreateLoginStart = () => ({

    type : "CREATE_LOGIN_START",

})
export const CreateLoginSuccess = (login) => ({

    type : "CREATE_LOGIN_SUCCESS",
    payload : login

})
export const CreateLoginFailure = () => ({

    type : "CREATE_LOGIN_FAILURE",

})

export const logout = () => ({
    type: "LOGOUT",
  });