import React from 'react'
import Footer from '../../components/Footer/Footer';
import Nav_top from '../../Nav_top/Nav_top';
import './Return.scss'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Return = ({openBag , setOpenBag , rates}) => {
  const {pathname} = useLocation()
  useEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth" , 
      });
    }, [pathname]);


return (
  <div className='return'>
       <Nav_top openBag={openBag} setOpenBag={setOpenBag}  rates={rates} />
       <div className='return_wrapper'> 
          <div className='return_container'> 
          <span className='title'>  politique de livraison </span>
              <div className='return_left'> 
                  <div className='return_title_section'> 
                      <span>Conformément aux dispositions de l’article L 121-20 du Code de la consommation :
                      <br/> <br/>  
                      L'acheteur dispose d'un délai de quatorze jours, à compter de la réception des produits, pour exercer son droit de rétractation 
                      sans avoir à justifier de motifs ni à payer de pénalités, à l'exception, le cas échéant, des frais de retour retours.
                      <br/> <br/>  
                      Les articles seront remboursés si et seulement si ils sont renvoyés dans le même état et dans leur emballage d'origine. 
                      <br/><br/>  
                      - Merci d'insérer le formulaire de retour que vous imprimerez dans le colis retour. <a href='https://cdn.shopify.com/s/files/1/0268/6215/8021/files/retour.pdf?v=1602529771' target="_blank" >  Cliquez-ici pour télécharger le formulaire de retour. </a>  
                     
                      <br/><br/>  
                      - ​Les retours se font via Colissimo à votre charge. Vous pouvez consulter les tarifs en vigueur pour Colissimo en bureau de poste ou sur internet.
                       Les frais varient en fonction du poids du colis.
                      <br/><br/>  
                      - La société n'est pas responsable en cas de perte ou détérioration des colis retournés. 
                      - Merci de nous contacter via la rubrique contact du site internet pour obtenir l'adresse d'expédition pour les colis. 
                      </span>
                  </div>
              </div>
          </div>
       </div>
       <Footer/>
    </div>
  )
}

export default Return
