import React, { useContext } from 'react'
import Footer from '../../components/Footer/Footer'
import Nav_top from '../../Nav_top/Nav_top'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import './Login.scss'
import axios from 'axios'
import { CreateLoginFailure, CreateLoginStart, CreateLoginSuccess } from '../../Context/LoginContext/LoginActions'
import { LoginContext } from '../../Context/LoginContext/LoginContext'
import { GoPrimitiveDot } from 'react-icons/go'
const Login = ({ rates, openBag, setOpenBag }) => {

    const [reset, setReset] = useState(false)
    const [loginInfos, setLoginInfos] = useState([])
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [invalid, setInvalid] = useState(false)

    const navigateRegister = () => {

        navigate('/account/register')

    }
    const { pathname } = useLocation()
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);


    const handleChnage = (e) => {
        let value = e.target.value;
        setLoginInfos({ ...loginInfos, [e.target.name]: value })
    }

    const { dispatch } = useContext(LoginContext)

    const createRegister = async (login, dispatch) => {

        dispatch(CreateLoginStart());
        try {
            setError(false)
            const res = await axios.post("https://caftannsapi-6.onrender.com/api/login", login);
            dispatch(CreateLoginSuccess(res.data))
            navigate('/account')
            setError(false)

        }
        catch (err) {
            setTimeout(() => {
                setError(true)
            }, 1000);
            dispatch(CreateLoginFailure())
        }

    }

    var sizeInfos = Object.keys(loginInfos).length;

    useEffect(() => {
        if (loginInfos?.Email !== "" && loginInfos?.Password !== "") {
            setInvalid(false)
        }
        else {
            setInvalid(true)
        }
    }, [loginInfos?.Email, loginInfos?.Password])


    const submitLogin = (e) => {
        e.stopPropagation()
        createRegister(loginInfos, dispatch)
        setErrorMessage(true)
    }


    console.log(loginInfos)



    return (
        <div className='login'>
            <div className='navbar' id="navbar"  >
                <Nav_top rates={rates} openBag={openBag} setOpenBag={setOpenBag} />
            </div>
            <div className='login_wrapper'>
                <div className='login_container'>
                    <span> CONNEXION </span>
                    {!reset ?
                        <>
                            {((error || invalid) && errorMessage) ?
                                <div className='input_items_error'>
                                    {invalid && errorMessage && <span>  <GoPrimitiveDot /> Tous les champs doivent etre rempli. </span>}
                                    {error && errorMessage && <span>  <GoPrimitiveDot /> E-mail ou mot de passe incorrect. </span>}
                                </div>
                                : null
                            }
                            <div onClick={(e) => { e.stopPropagation(); setErrorMessage(false) }} className='input_items'>
                                <label>  mail  </label>
                                <input type="email" name="Email" onChange={handleChnage} />
                            </div>
                            <div onClick={(e) => { e.stopPropagation(); setErrorMessage(false) }} className='input_items'>
                                <div className='mdp'>  <label>  MOT DE PASSE  </label> <span className='mdp_reset' onClick={() => setReset(true)} > Mot de passe oublié?  </span>     </div>
                                <input type="password" name="Password" onChange={handleChnage} />
                            </div>

                            <button className='button' onClick={submitLogin}> SE CONNECTER </button>
                            <span className='reject' onClick={navigateRegister}> Créer un compte</span>

                        </>

                        : <>
                            <h3> RÉINITIALISER VOTRE MOT DE PASSE</h3>
                            <p>Nous vous ferons parvenir un mail pour réinitialiser votre mot de passe.</p>
                            <div className='input_items'>
                                <label>  mail  </label>
                                <input type="text" />
                            </div>
                            <button className='button'> SOUMETTRE </button>
                            <span className='reject'> Annuler</span>
                        </>
                    }

                </div>
            </div>
            <Footer />


        </div>
    )
}

export default Login
