import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import BasketProvider from './Context/BasketContext/Basket';
import { ReviewContextProvider } from './Context/ReviewContext/ReviewContext';
import {RegisterContextProvider} from './Context/RegisterContext/RegisterContext'
import { LoginContextProvider } from './Context/LoginContext/LoginContext';
import { ContactContextProvider } from './Context/ContactContext/ContactContext';
import { NewsContextProvider } from './Context/NewsletterContext/NewsletterContext';
import { OrderContextProvider } from './Context/OrderContext/OrderContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
     <OrderContextProvider>
          <ContactContextProvider>
          <NewsContextProvider>
                    <LoginContextProvider>
                    <RegisterContextProvider>
                    <ReviewContextProvider>
                         <BasketProvider>
                              <App />
                         </BasketProvider>
                    </ReviewContextProvider>
                    </RegisterContextProvider>
                    </LoginContextProvider>
          </NewsContextProvider>
          </ContactContextProvider>
  </OrderContextProvider>



);


