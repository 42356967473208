import React, { useRef } from 'react'
import './Nav_top.scss'
import { SiTiktok } from "react-icons/si"
import { SiInstagram } from "react-icons/si"
import { SiSnapchat } from "react-icons/si"
import { SiPinterest } from "react-icons/si"
import ReactCountryFlag from "react-country-flag"
import { useState } from 'react'
import { VscClose } from "react-icons/vsc"
import { CiSearch } from "react-icons/ci"
import logo1 from "../images/logo.svg"
import { RxPerson } from "react-icons/rx"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { SlHandbag } from "react-icons/sl"
import { TfiClose } from "react-icons/tfi";
import hamburger from "../images/A.svg"
import Navbar_media from '../Navbar_media/Navbar_media'
import axios from "axios"
import { useEffect } from 'react'
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { BasketContext } from '../Context/BasketContext/Basket'
import { useContext } from 'react'
var Carousel = require('react-responsive-carousel').Carousel;


const Nav_top = ({ scroll, rates, setOpenBag, selectedCurrency, checkRates, setSelectedCurrency, selected, setSelected }) => {

  const [currency, setCurrency] = useState(false);
  const [category, setCategory] = useState(false);
  const [collection, setCollection] = useState(false);
  const [maison, setMaison] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [navbarLeft, setNavbarLeft] = useState(false)
  const [searchProduct, setSearchProduct] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchValue1, setSearchValue1] = useState("");
  const [load, setLoad] = useState(false);
  const page = 0
  const navigate = useNavigate()

  const ref = useRef(null)
  const ref1 = useRef(null)
  const ref3 = useRef(null)
  const ref2 = useRef(null)
  const ref5 = useRef(null)


  const selcetdCurrency = () => {
    setCurrency(false);

  }
  const heightOf = (ref.current?.clientHeight) - 3;
  const heightOfSearch = (ref1.current?.clientHeight) + heightOf
  const heightOfSearch1 = (ref5.current?.clientHeight) + heightOf


  useEffect(() => {
    setLoad(true)
    axios.get(`https://caftannsapi-6.onrender.com/api/products/product/searchBytitle/title?produitTitre=${searchValue1}`)
      .then(response => {
        setSearchProduct(response.data)
        setLoad(false)
      })

  }, [searchValue1])


  const handleClose = () => {
    setOpenSearch(false)
    setSearchValue("")
  }

  const searchNavigate = () => {

    navigate({
      pathname: `/search`,
      search: `?type=product&productName=${searchValue1}`,
    })
    setOpenSearch(false)
    setSearchValue("")


  }

  const itemSearch = () => {
    setSearchValue("")
    setOpenSearch(false)
  }


  var myIndex = 0;
  carousel();
  function carousel() {
    var i;
    var x = document.getElementsByClassName("slides");
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    myIndex++;
    if (myIndex > x.length) { myIndex = 0 }
    if (x[myIndex - 1]) {
      x[myIndex - 1].style.display = "block";
    }
    setTimeout(carousel, 4000); // Change image every 2 seconds
  }

  /// si la rechreche dépasse 4 élements on va aficher que 4 élements 
  const setProductFilter = searchProduct.slice(0, 4)

  const handleChangeValue = (e) => {

    setSearchValue(e.target.value)
    setSearchValue1(e.target.value)

  }


  const basket = useContext(BasketContext)

  const navigateAccount = () => {

    const login = JSON.parse(localStorage.getItem('login_user'))

    if (login.length === 0) {
      navigate('/account/login')

    } else {
      navigate('/account')
    }

  }

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }


  return (
    <>
      {currency === true &&
        <div className='all_currencies' id="all_currencies" >
          <div className='container_currencies'>
            <div id="close_bar"> <TfiClose id="close" onClick={() => setCurrency(false)} /></div>
            <div className='table_currencies'>
              <button className='currency-option-button' onClick={selcetdCurrency}>
                <ReactCountryFlag
                  countryCode="MA" svg id='flag' title="MA"
                  onClick={() => { setSelectedCurrency('MAD'); setSelected("MA") }}
                />
                <span id="nameFlag" onClick={() => { setSelectedCurrency('MAD'); setSelected("MA") }}
                  style={selected === "MA" ? { borderBottom: '2px solid #1a1113ca' } : { borderBottom: "0px" }}
                > MAD </span>
              </button>
              <button className='currency-option-button' onClick={selcetdCurrency}>
                <ReactCountryFlag
                  countryCode="FR" svg id='flag' title="FR"
                  onClick={() => { setSelectedCurrency('EUR'); setSelected("FR") }}
                />
                <span id="nameFlag" onClick={() => { setSelectedCurrency('EUR'); setSelected("FR") }}
                  style={selected === "FR" ? { borderBottom: '2px solid #1a1113ca' } : { borderBottom: "0px" }}
                > EUR </span>
              </button>
              <button className='currency-option-button' onClick={selcetdCurrency}>
                <ReactCountryFlag
                  countryCode="US" svg id='flag' title="US"
                  onClick={() => { setSelectedCurrency('USD'); setSelected("US") }}
                />
                <span id="nameFlag" onClick={() => { setSelectedCurrency('USD'); setSelected("US") }}
                  style={selected === "US" ? { borderBottom: '2px solid #1a1113ca' } : { borderBottom: "0px" }}
                > USD </span>
              </button>
              <button className='currency-option-button' onClick={selcetdCurrency}>
                <ReactCountryFlag
                  countryCode="AE" svg id='flag' title="UAE"
                  onClick={() => { setSelectedCurrency('AED'); setSelected("AE") }}
                />
                <span id="nameFlag" onClick={() => { setSelectedCurrency('AED'); setSelected("AE") }}
                  style={selected === "AE" ? { borderBottom: '2px solid #1a1113ca' } : { borderBottom: "0px" }}
                > AED </span>
              </button>
              <button className='currency-option-button' onClick={selcetdCurrency}>
                <ReactCountryFlag
                  countryCode="QA" svg id='flag' title="QA"
                  onClick={() => { setSelectedCurrency('QAR'); setSelected("QA") }}
                />
                <span id="nameFlag" onClick={() => { setSelectedCurrency('QAR'); setSelected("QA") }}
                  style={selected === "QA" ? { borderBottom: '2px solid #1a1113ca' } : { borderBottom: "0px" }}
                > QAR </span>
              </button>
              <button className='currency-option-button' onClick={selcetdCurrency}>
                <ReactCountryFlag
                  countryCode="GB" svg id='flag' title="GB"
                  onClick={() => { setSelectedCurrency('GBP'); setSelected("GB") }}
                />
                <span id="nameFlag" onClick={() => { setSelectedCurrency('GBP'); setSelected("GB") }}
                  style={selected === "GB" ? { borderBottom: '2px solid #1a1113ca' } : { borderBottom: "0px" }}
                > GBP </span>
              </button>

            </div>
            <div id="info_message">  <p> Les commandes seront facturées en EUR.  </p></div>
          </div>
        </div>}
      <div className='nav_bar'>
        <div className='top' ref={ref}>
          <div className='conatainer'>
            <p id="phoneNumber"> +33 711 123 432 </p>
            <Carousel showThumbs={false} className='carousel' showArrows={false} infiniteLoop showIndicators={false} showStatus={false} useKeyboardArrows={false} autoPlay>
              <p id="text">Livraison offerte en France métropolitaine <span>à partir de 300€ d'achats.</span></p>
              <p id="text"> PAIEMENTS SÉCURISÉS<span> Par Paypal et cartes bancaires</span>  </p>
              <p id="text">Échanges & remboursements possibles <span> sous 14 jours</span> </p>
            </Carousel>
            <div className='social'>
              <SiTiktok id="icon" />
              <a href='https://www.instagram.com/caftan.ns/' target="_blank">    <SiInstagram id="icon" />   </a>
              <SiSnapchat id="icon" />
              <SiPinterest id="icon" />
            </div>
          </div>
        </div>
        <div className='bottom' ref={ref2}>
          <div className='container'>
            <div className='container_left'>
              <a href='/pages/delais-de-livraison'> DELAI DE LIVRAISON </a>
              <a href='/pages/guide-des-tailles'> GUIDE DES TAILLES </a>
              <a href='/pages/faq'> FAQ </a>
              <a href='/pages/nous-conntacter'> NOUS CONTACTER </a>
            </div>
            <div className='container_right'>
              <div className='flags' onClick={() => !checkRates && setCurrency(true)} >
                <div className='container_flag'>
                  <ReactCountryFlag
                    countryCode={selected} svg id='flag' title={selected}
                  />
                  <ReactCountryFlag countryCode={selected === "FR" ? "EUR" : selected === "MA" ? "MAD" : selected === "US" ? "USD" : selected === "AE" ? "AED" : selected === "QA" ? "QAR" : selected === "GB" && "GBP"}
                    id="nameFlag" />
                </div>

              </div>
            </div>
          </div>
        </div>
        {scroll <= 120 ?
          <div className='site-header'>
            <div className='page-width'>
              <div className='header-layout-search'>
                <CiSearch id="search" onClick={() => setOpenSearch(true)} />
                <img src={hamburger} alt='Ns caftan Menu' id="hamburger" onClick={() => setNavbarLeft(true)} />
              </div>
              <Link to="/" className='header-layout-logo'>
                <img ref={ref3} src={logo1} alt='Ns caftan Logo' />
              </Link>
              <div className='header-layout-shop'  >
                <div className='icons'>
                  <CiSearch id="icon" onClick={() => setOpenSearch(true)} />
                  <RxPerson onClick={navigateAccount} id="icon1" />
                  <div onClick={() => setOpenBag(true)} style={{ position: "relative", cursor: "pointer" }}>
                    <span className='basketNumbers' >{basket.items.length}</span>
                    <SlHandbag id="icon2" />
                  </div>
                </div>
              </div>

            </div>
            {openSearch === true &&
              <>
                <div className='search-section' onClick={handleClose}>
                </div>
                <div className='site-header_search' style={{ marginTop: heightOf }} ref={ref1} >
                  <div className='page-width'>
                    <form className="search-form">
                      <button onClick={searchNavigate}> <CiSearch id="search-icon" />  </button>
                      <input onChange={handleChangeValue} placeholder='Rechercher dans la boutique' />
                    </form>
                    <div className="close-button" onClick={handleClose}> <VscClose id="close" />  </div>
                  </div>
                </div>
                {searchProduct.length > 0 && searchValue != "" && openSearch === true ?
                  <div className="search_results" style={{ top: heightOfSearch }}  >
                    <div className="grid-product">
                      {setProductFilter.map((product) => (
                        <Link to= {`/collections/${product.produitTitre}/products/${product.produitTitre}?_id=${product._id}`} onClick={itemSearch} className='grid-item'>
                          <div className="grid__image-ratio">
                            <img src={product.produitPrincipleImage}  alt={product.produitCategorie}/>
                          </div>
                          <div className='grid-product_title'>
                            <p>  {product.produitTitre}  </p>
                            <div>
                              <span className='price'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs." : selectedCurrency === "GBP" ? "£" : selectedCurrency === "QAR" ? "QAR" : null} {(product?.produitPrix * rates).toFixed(2)} {selectedCurrency === "EUR" ? "€" : selectedCurrency === "MAD" ? "dh" : null} </span>
                            </div>
                          </div>
                        </Link>
                      ))
                      }
                    </div>
                    <div className='more_results'>
                      <button onClick={searchNavigate}>   VOIR PLUS  </button>
                    </div>
                  </div>

                  : null
                }
              </>
            }
            <div className='navigation-section'>
              <div className='navigation-items ' >
                <li className='list'    >
                  <Link onClick={handleTop} to={`/collections/nouveautes`} className="nav" >  <li>  NOUVEAUTÉS </li>   </Link>

                </li>
                <li className='list' onMouseLeave={() => setCategory(false)}> <a herf="" className="nav" onMouseEnter={() => setCategory(true)}> CATÉGORIE </a>
                  {category &&
                    <ul className='nav-items-category' >
                      <Link onClick={handleTop} to={`/collections/caftan`} > <li> CAFTAN </li>  </Link>
                      <Link onClick={handleTop} to={`/collections/jellaba`} >    <li> JELLABA </li></Link>
                      <Link onClick={handleTop} to={`/collections/takchita`} >     <li> TAKCHITA</li></Link>
                      <Link onClick={handleTop} to={`/collections/abaya`} >     <li> ABAYA</li></Link>
                      <Link onClick={handleTop} to={`/collections/gandoura`} >   <li> GANDOURA</li></Link>
                      <Link onClick={handleTop} to={`/collections/kimono`} >    <li> KIMONO</li></Link>
                    </ul>}
                </li>
                {/* <li className='list' onMouseLeave={() => setCollection(false)}> <a herf="" className="nav" onMouseEnter={() => setCollection(true)} > COLLECTIONS </a>
                  {collection &&
                    <ul className='nav-items-collections' >
                      <Link onClick={handleTop} to={`/collections/caftan-marocain`} > <li> CAFTAN MAROCAIN </li>  </Link>
                      <Link onClick={handleTop} to={`/collections/mariage-marocain`} > <li> MARIAGE MAROCAIN</li></Link>
                      <Link onClick={handleTop} to={`/collections/abaya-marocaine`} > <li> ABAYA MAROCAINE</li></Link>
                    </ul>}
                </li> */}
                <li className='list'    >
                  <Link onClick={handleTop} to={`/collections/solde`} className="nav" >   SALE </Link>

                  <p id="sale"> SALE </p>

                </li>
                <li className='list' onMouseLeave={() => setMaison(false)}> <a herf="" className="nav" onMouseEnter={() => setMaison(true)} > LA MAISON </a>
                  {maison &&
                    <ul className='nav-items-maison' >
                      <li> LA MAISON </li>
                      <li> INSPIRATION </li>
                      <Link onClick={handleTop} to={`/pages/faq`} >     <li> FAQ</li></Link>
                    </ul>}
                </li>

              </div>
            </div>
            {navbarLeft === true &&
              <div >
                <Navbar_media navbarLeft={navbarLeft} setNavbarLeft={setNavbarLeft} setCurrency={setCurrency} currency={currency} selected={selected} />
              </div>
            }
          </div>
          : <div className='site-header__scroll'>
            <div className='page-width'>
              <div className='header-layout-search'>
                <CiSearch id="search" onClick={() => setOpenSearch(true)} />
                <img src={hamburger} id="hamburger" alt='Ns CAFTAN Menu' onClick={() => setNavbarLeft(true)} />
              </div>
              <Link to="/" className='header-layout-logo'>
                <img ref={ref3} src={logo1} alt='Ns Caftan Logo' />
              </Link>
              <div className='header-layout-shop'  >
                <div className='icons'>
                  <CiSearch id="icon" onClick={() => setOpenSearch(true)} />
                  <RxPerson onClick={navigateAccount} id="icon1" />
                  <div onClick={() => setOpenBag(true)} style={{ position: "relative", cursor: "pointer" }}>
                    <span className='basketNumbers' >{basket.items.length}</span>
                    <SlHandbag id="icon2" />
                  </div>
                </div>
              </div>

            </div>
            {openSearch === true &&
              <>
                <div className='search-section' onClick={() => setOpenSearch(false)}>
                </div>
                <div className='site-header_search' style={{ marginTop: heightOf }} ref={ref5} >
                  <div className='page-width'>
                    <form className="search-form">
                      <button onClick={searchNavigate} > <CiSearch id="search-icon" />  </button>
                      <input onChange={handleChangeValue} placeholder='Rechercher dans la boutique' />
                    </form>
                    <div className="close-button" onClick={handleClose}> <VscClose id="close" />  </div>
                  </div>
                </div>
                {searchProduct.length > 0 && searchValue != "" ?
                  <div className="search_results" style={{ top: heightOfSearch1 }}  >
                    <div className="grid-product">
                      {setProductFilter.map((product) => (
                        <Link to={`/collections/${product.produitCategorie}/products/${product.produitTitre}?_id=${product._id}`} onClick={itemSearch} className='grid-item'>
                          <div className="grid__image-ratio">
                            <img src={product.produitPrincipleImage} alt={product.produitCategorie} />
                          </div>
                          <div className='grid-product_title'>
                            <p>  {product.produitTitre}  </p>
                            <div>
                              <span className='price'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs." : selectedCurrency === "GBP" ? "£" : selectedCurrency === "QAR" ? "QAR" : null} {(product?.produitPrix * rates).toFixed(2)} {selectedCurrency === "EUR" ? "€" : selectedCurrency === "MAD" ? "dh" : null} </span>
                            </div>
                          </div>

                        </Link>
                      ))

                      }

                    </div>
                    <div className='more_results'>

                      <button onClick={searchNavigate}>   VOIR PLUS  </button>
                    </div>
                  </div>
                  : null
                }
              </>
            }
            <div className='navigation-section'>
              <div className='navigation-items ' >
                <li className='list'    >
                  <Link onClick={handleTop} to={`/collections/nouveautes`} className="nav" >  <li>  NOUVEAUTÉS </li>   </Link>

                </li>
                <li className='list' onMouseLeave={() => setCategory(false)}> <a herf="" className="nav" onMouseEnter={() => setCategory(true)}> CATÉGORIE </a>
                  {category &&
                    <ul className='nav-items-category' >
                      <Link onClick={handleTop} to={`/collections/caftan`} > <li> CAFTAN </li>  </Link>
                      <Link onClick={handleTop} to={`/collections/jellaba`} >    <li> JELLABA </li></Link>
                      <Link onClick={handleTop} to={`/collections/takchita`} >     <li> TAKCHITA</li></Link>
                      <Link onClick={handleTop} to={`/collections/abaya`} >     <li> ABAYA</li></Link>
                      <Link onClick={handleTop} to={`/collections/gandoura`} >   <li> GANDOURA</li></Link>
                      <Link onClick={handleTop} to={`/collections/kimono`} >    <li> KIMONO</li></Link>
                    </ul>}
                </li>
                {/* <li className='list' onMouseLeave={() => setCollection(false)}> <a herf="" className="nav" onMouseEnter={() => setCollection(true)} > COLLECTIONS </a>
                  {collection &&
                    <ul className='nav-items-collections' >
                      <Link onClick={handleTop} to={`/collections/caftan-marocain`} > <li> CAFTAN MAROCAIN </li>  </Link>
                      <Link onClick={handleTop} to={`/collections/mariage-marocain`} > <li> MARIAGE MAROCAIN</li></Link>
                      <Link onClick={handleTop} to={`/collections/abaya-marocaine`} > <li> ABAYA MAROCAINE</li></Link>
                    </ul>}
                </li> */}
                <li className='list'    >
                  <Link onClick={handleTop} to={`/collections/solde`} className="nav" >   SALE </Link>
                  <p id="sale"> SALE </p>

                </li>
                <li className='list' onMouseLeave={() => setMaison(false)}> <a herf="" className="nav" onMouseEnter={() => setMaison(true)} > LA MAISON </a>
                  {maison &&
                    <ul className='nav-items-maison' >
                      <li> LA MAISON </li>
                      <li> INSPIRATION </li>
                      <Link onClick={handleTop} to={`/pages/faq`} >     <li> FAQ</li></Link>
                    </ul>}
                </li>

              </div>
            </div>
            {navbarLeft === true &&
              <div >
                <Navbar_media navbarLeft={navbarLeft} setNavbarLeft={setNavbarLeft} setCurrency={setCurrency} currency={currency} selected={selected} />
              </div>
            }
          </div>
        }
      </div>


    </>
  )
}

export default Nav_top
