

export const CreateOrderStart = () => ({

    type : "CREATE_ORDER_START",

})
export const CreateOrderSuccess = (order) => ({

    type : "CREATE_ORDER_SUCCESS",
    payload : order

})
export const CreateOrderFailure = () => ({

    type : "CREATE_ORDER_FAILURE",

})
export const logoutUser = () => ({
    type: "LOGOUT_USER",
  });