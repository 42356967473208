import React from 'react'
import Footer from '../components/Footer/Footer'
import Nav_top from '../Nav_top/Nav_top'
import './HomePage.scss'
import HomeSection from './HomeSection/HomeSection'
const HomePage = ({scroll , openBag , setOpenBag  , openShop ,rates , checkRates, setOpenShop , selectedCurrency , setSelectedCurrency , selected , setSelected}) => {


  return (
    <div className='homePage'>
          <Nav_top rates={rates}  selected={selected} setSelected={setSelected} selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} openBag={openBag} setOpenBag={setOpenBag} checkRates={checkRates} scroll={scroll} />
             <HomeSection  selectedCurrency={selectedCurrency} rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} setOpenShop={setOpenShop} openShop={setOpenShop} />
         <Footer/>
    </div>
  )
}

export default HomePage
