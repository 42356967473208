import React from 'react'
import Footer from '../../components/Footer/Footer'
import Nav_top from '../../Nav_top/Nav_top'
import './Contact.scss'
import { useState } from 'react'
import axios from "axios"
import { useContext } from 'react'
import { ContactContext } from '../../Context/ContactContext/ContactContext'
import { CreateContactFailure, CreateContactStart, CreateContactSuccess } from '../../Context/ContactContext/ContactActions'
import { useEffect } from 'react'
import { GoPrimitiveDot } from 'react-icons/go'
import { useLocation } from 'react-router-dom'

const Contact = ({openBag , rates , setOpenBag}) => {

    const [contactInfos , setContactinfos] = useState({})
    const [ emailInvalid ,setEmailInvalid] = useState(false)
    const [showMessage , setShowMessage] = useState(false)

    const handleChange = (e)  => {
       let value = e.target.value
        setContactinfos({...contactInfos , [e.target.name]:value})
    }

    const {dispatch} = useContext(ContactContext)
    
   const createContact = async (contact , dispatch) =>{
        
        dispatch(CreateContactStart());
        try {
            const res = await axios.post("https://caftannsapi-6.onrender.com/api/contact", contact);
            dispatch(CreateContactSuccess)
        }
        catch(err) {
            dispatch(CreateContactFailure())
        }
    }
    const submitContact = (e) => {
        e.stopPropagation()
        createContact(contactInfos , dispatch)
        
        setShowMessage(true)
    }

    // check if the email is not valid 

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }
      useEffect(() => {
        if (!isValidEmail(contactInfos?.Email)) { // si l'email est valid on continue le processus 
            setEmailInvalid(true)
        }
        else {
            setEmailInvalid(false)
           }
      }, [contactInfos])

      console.log(emailInvalid)

        const   handleClick = (e) => {

            e.stopPropagation()
            if(emailInvalid === true) {
                setShowMessage(false)
            }

      }

      const {pathname} = useLocation()
      useEffect(() => {
          document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth" , 
          });
        }, [pathname]);
  


  return (
    <div className='contact'  >
         <Nav_top rates={rates} openBag={openBag} setOpenBag={setOpenBag}  />
         <div className='contact_wrapper'> 
            <div className='contact_container'> 
            <span className='title'>  contactez-nous </span>
                <div className='container'> 
                { emailInvalid && showMessage && 
                <div className='errors'> 
                        <span>  <GoPrimitiveDot/>   E-mail invalid. </span>
                </div>
                }
                { !emailInvalid && showMessage && 
                    <div className='valide'> 
                            <span>  <GoPrimitiveDot/>   Merci de nous avoir avoir contacté. Nous vous reviendrons le plus rapidement possible. </span>
                    </div>
                    }
             
                  <div className='inputs_wrapper'>
                  <div className='items'>
                        <label> NOM   </label>
                        <input name="Name"  onClick={handleClick} onChange={handleChange} className='input'/>
                  </div>
                    <div className='items'>
                         <label> MAIL   </label>
                        <input name="Email" onClick={handleClick}  onChange={handleChange} className='input'/>
                     </div>

                  </div>
                     <div className='items text'>
                         <label> MESSAGE   </label>
                        <textarea name="Message" onClick={handleClick}  onChange={handleChange} className='input textArea'/>
                     </div>
                     <button className='send' onClick={submitContact}>ENVOYER</button>
                     <span className='politique'> Ce site est protégé par reCAPTCHA, et la Politique de confidentialité et les Conditions d'utilisation de Google s'appliquent.  </span>
                </div>
            </div>
         </div>
         <Footer/>
    </div>
  )
}

export default Contact
