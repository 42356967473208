import React from 'react'
import './Singlproduct.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react"
import axios from "axios"
import { Pagination, Navigation } from "swiper";
import { GoTriangleDown } from "react-icons/go"
import { GoTriangleUp } from "react-icons/go"
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi'
import { FacebookShareButton, PinterestShareButton, TwitterShareButton } from "react-share"
import { BsFacebook, BsTwitter } from "react-icons/bs"
import { FaPinterest } from "react-icons/fa"
import { ReviewContext } from '../../Context/ReviewContext/ReviewContext';
import { useContext } from "react"
import { createReview } from '../../Context/ReviewContext/ReviewApi';
import { CreateReviewFailure, CreateReviewSuccess, CreateReviewStart } from "../../Context/ReviewContext/ReviewActions";
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { BasketContext } from '../../Context/BasketContext/Basket';
import { useLocation } from 'react-router-dom';


const Singlproduct = ({ openBag, setOpenBag, props, rates, selectedCurrency }) => {


    const [productValue, setProductValue] = useState([]);
    const [swiperRef, setSwiperRef] = useState(null);
    const [colorChecked, setColorChecked] = useState('');
    const [selectedDetailsId, setSelectedDetailsId] = useState(''); /// le ID general du details options // level 3
    const [selectedSizeId, setSelectedSizeId] = useState(''); // id de la taille spécifque // level 4
    const [imageSelected, setImageSelected] = useState('');
    const [quantityValue, setQuantityValue] = useState(1);
    const [defaultQuantity, setDefaultQuantity] = useState('');
    const [showShipping, setShowShipping] = useState(false);
    const [showShipping1, setShowShipping1] = useState(false);
    const [reviewShow, setReviewShow] = useState(false);
    const [reviewInfos, setReviewInfos] = useState({});
    const [loadReview, setLoadReview] = useState(false);
    const [endRequest, setEndRequest] = useState(false);
    const [errorReview, setErrorReview] = useState(false)
    const [titleSize, setTitleSize] = useState("")
    const [load, setLoad] = useState(false)



    /// creation de l'avis 
    const createReview = async (review, dispatch) => {

        dispatch(CreateReviewStart());
        try {
            setLoadReview(true);
            setErrorReview(false)

            await axios.post("https://caftannsapi-6.onrender.com/api/product/review", review);
            dispatch(CreateReviewSuccess)
            setInterval(() => {
                setLoadReview(false);
                setEndRequest(true)
                setErrorReview(false)

            }, 3000);
        }
        catch (err) {
            setLoadReview(true);
            dispatch(CreateReviewFailure())
            setInterval(() => {
                setLoadReview(false);
                setErrorReview(true)
            }, 3000);

        }
    }
    /// remplire les infos dans reviewInfos
    const handleChnage = (e) => {

        let value = e.target.value
        setReviewInfos({ ...reviewInfos, [e.target.name]: value })
    }
    const { dispatch } = useContext(ReviewContext)
    const submitReview = () => {
        createReview(reviewInfos, dispatch)

    }
    const params = new URLSearchParams(window.location.search)
    const idValue = params.get('_id')






    /// get the product
    useEffect(() => {
        axios.get(`https://caftannsapi-6.onrender.com/api/products/${idValue}`,)
            .then(response => {
                setProductValue(response.data)
            })
    }, [idValue])



    const table = []
    const idSize = []
    const sises = []

    var current = new Date();
    var next = new Date();

    current.setDate(current.getDate());
    next.setDate(next.getDate() + 10);

    const TitreDefault = []

    {
        productValue?.productDetails?.map((product) => {
            product.details?.map((details) => {
                table.push(details.colorOption)
                idSize.push(details._id)
                details.variables?.map((idSelected) => {

                    if (details._id === selectedDetailsId) {
                        sises.push(idSelected._id)
                        TitreDefault.push(idSelected.titre)


                    }
                    if (selectedDetailsId === "") {
                        sises.push(idSelected._id)
                        TitreDefault.push(idSelected.titre)

                    }

                })
            }
            )
        })
    }
    const color = table[0]
    const size = idSize[0]
    const TitreSizeDefault = TitreDefault[0]
    const sizeDefault = sises[0]
    const quantMax = []
    const quantityDefault = []
    const quantityValueDefault = quantityDefault[0]


    productValue?.productDetails?.map((product) => {

        product?.details?.map((details) => {
            details.variables?.map((variable) => {
                if (selectedSizeId === variable._id) {
                    quantMax.push(variable.quantity)
                }
            }


            )
        })
    })
    productValue?.productDetails?.map((product) => {

        product?.details?.map((details) => {
            details.variables.map((variable) => {


                if ((sizeDefault === variable._id) && (quantMax[0] === undefined)) {
                    quantityDefault.push(variable.quantity)
                }
            }

            )
        })
    })

    const url = window.location.href
    let quantity = 0
    let idQuant = ""

    productValue?.productDetails?.map((detail) => (
        detail.details?.map((item) => {
            item.variables?.map((variable) => {
                for (let i = 0; i < item.variables.length; i++) {
                    quantity += variable.quantity
                    idQuant = productValue._id
                    return quantity
                }
            })
        })

    ))

    //// add to cart 


    const basket = useContext(BasketContext)
    const addToBasket1 = (e) => {
        e.preventDefault();

        setTimeout(() => {
            setLoad(true)
        }, 2000);

        setTimeout(() => {

            setOpenBag(true);

            basket.addOneToBasket(
                (!selectedSizeId ? sizeDefault : selectedSizeId),
                (!selectedDetailsId ? size : selectedDetailsId),
                quantityValue,
                productValue?.produitPrix,
                (!imageSelected ? productValue.produitPrincipleImage : imageSelected),
                (titleSize === "" ? TitreSizeDefault : titleSize),
                (!quantMax[0] ? quantityDefault[0] : quantMax[0]),
                productValue.produitTitre,
                (!colorChecked ? color : colorChecked),
                productValue._id
            )
        }, 2000)
    }

    const { pathname } = useLocation()
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [pathname]);

    return (


        <div className='singl-Product'>

            <div className='product-content'>
                <div className='photos'>
                    <div className="product-photo">
                        {
                            imageSelected === "" ? < img src={selectedDetailsId === "" ? productValue?.produitPrincipleImage : imageSelected} alt={productValue?.produitPrincipleImage} />
                                : <img src={imageSelected} alt={productValue?.produitPrincipleImage} />
                         }
                    </div>
                    <Swiper className='mySwiper'
                        onSwiper={setSwiperRef}
                        slidesPerView={3}
                        spaceBetween={20}

                        pagination={{
                            type: "fraction",
                        }}
                        navigation={true}
                        modules={[Navigation]}
                    >
                        {productValue?.productDetails?.map((product) => (
                            product.details.map((details) => (
                                selectedDetailsId === details._id ?
                                    details.images.map((img, i) => (
                                        <SwiperSlide key={i} className='grid-photos' onClick={() => setImageSelected(img)} >
                                            <img src={img} />
                                        </SwiperSlide>
                                    ))
                                    : (selectedDetailsId === "" && details._id === size)
                                        ? details.images.map((img, i) => (
                                            <SwiperSlide key={i} className='grid-photos' onClick={() => setImageSelected(img)} >
                                                <img src={img} />
                                            </SwiperSlide>
                                        ))
                                        : null
                            ))
                        ))
                        }
                    </Swiper>
                </div>
                <div className="product-info" >
                    <div className="product-info_conainer">
                        <div className="product-single_meta">
                            <div className='title'>
                                <span> {productValue?.produitTitre}  </span>
                            </div>
                            <div className='price'>
                                <span id='actual'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs." : selectedCurrency === "GBP" ? "£" : selectedCurrency === "QAR" ? "QAR" : null} {(productValue?.produitPrix * rates).toFixed(2)} {selectedCurrency === "EUR" ? "€" : selectedCurrency === "MAD" ? "dh" : null} </span>
                                {productValue?.produitPrevPrix &&
                                    <span id='prev'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs." : selectedCurrency === "GBP" ? "£" : selectedCurrency === "QAR" ? "QAR" : null} {(productValue?.produitPrevPrix * rates).toFixed(2)} {selectedCurrency === "EUR" ? "€" : selectedCurrency === "MAD" ? "dh" : null} </span>
                                }

                            </div>
                        </div>
                        <form className='form'>
                            <div className='selctor-wrapper_colors'>
                                <label>Couleur</label>
                                <fieldset className='fieldset' >
                                    {productValue?.productDetails?.map((detail) => (
                                        detail?.details?.map((product, i) => (
                                            <div key={i} className='color-options_wrapper' onClick={() => {
                                                setColorChecked(product.colorOption)
                                                setSelectedDetailsId(product._id)
                                                setImageSelected(product.url)
                                                setSelectedSizeId(product.variables[0]._id)
                                                setTitleSize("")
                                            }
                                            }  >
                                                <li data-title={product.colorOption} >
                                                    <img src={product?.url} id={colorChecked === product.colorOption ? "img" : (product.colorOption === color && colorChecked === "") ? "img" : " "} alt={productValue?.produitPrincipleImage} />
                                                </li>
                                            </div>
                                        ))))
                                    }
                                </fieldset>
                            </div>
                            <div className='selector-wrapper_sizes'>
                                <label>Taille</label>
                                <div className='size-options_wrapper'>
                                    {productValue?.productDetails?.map((detail) => (
                                        detail?.details?.map((product, i) => (
                                            selectedDetailsId === product._id ?
                                                product?.variables?.map((variable, i) => (
                                                    <span key={i} id={selectedSizeId === variable._id ? "span" : (variable._id === sizeDefault && selectedSizeId === "") ? "span" : ""} onClick={() => { setTitleSize(variable.titre); setSelectedSizeId(variable._id); setQuantityValue(variable.quantity > 0 ? 1 : 0) }} style={variable.quantity === 0 ? { textDecoration: "line-through", textDecorationColor: "rgb(136, 101, 36) " } : null}> {variable.titre} </span>
                                                ))
                                                : (selectedDetailsId === "" && size === product._id) ?
                                                    product?.variables?.map((variable) => (
                                                        <span id={selectedSizeId === variable._id ? "span" : (variable._id === sizeDefault && selectedSizeId === "") ? "span" : ""} onClick={() => { setTitleSize(variable.titre); setSelectedSizeId(variable._id); setDefaultQuantity(variable.quantity); setQuantityValue(variable.quantity > 0 ? 1 : 0) }} style={variable.quantity === 0 ? { textDecoration: "line-through", textDecorationColor: "rgb(136, 101, 36) " } : null} > {variable.titre} </span>
                                                    ))
                                                    : null
                                        ))))
                                    }
                                </div>

                            </div>
                            <div className='product-form_quantity'>
                                <div className="quantity">
                                    <input value={quantityValue} />
                                    <div className="down_up" >
                                        <GoTriangleUp className='icon' onClick={() => setQuantityValue((quantityValue < quantMax[0]) ? (quantityValue + 1) : quantMax[0])} />
                                        <GoTriangleDown className='icon' onClick={() => setQuantityValue(quantityValue > 0 ? (quantityValue - 1) : 1)} />
                                    </div>
                                </div>
                                <div className='addTo_cart'>
                                    {(quantMax[0] > 0) ?
                                        <button style={load ? { boxShadow: "inset 400px 0 0 0 rgb(145 104 15 / 92%)" } : { backgroundColor: "#b59148" }}
                                            id="add" onClick={addToBasket1}> AJOUTER AU PANIER </button>
                                        : (quantityDefault[0] > 0) ?
                                            <button id="add" onClick={addToBasket1} > AJOUTER AU PANIER </button>
                                            : <button id="epuisé" onClick={(e) => e.preventDefault()} > épuisé </button>
                                    }
                                </div>

                            </div>
                            <div className='product-block'>
                                <span>  Le mannequin mesure 1,65 m et porte habituellement la taille M   </span>
                            </div>
                            <div className='product-livraison_info'>
                                <div className='product-livraison_wrapper' style={{ borderBottom: "1px solid #826120" }}  >
                                    <div className='livraison-inter' onClick={() => setShowShipping(!showShipping)} >
                                        <span> Livraison internatioanl </span>
                                        {!showShipping ? <HiOutlineChevronDown id="down" />
                                            : <HiOutlineChevronUp id="down" />}
                                    </div>
                                    <div className={!showShipping ? 'livraison-inter_infos' : 'livraison-inter_infos_show'}>
                                        <span>   Nous livrons partout dans de nombreuses destinations.</span>
                                    </div>
                                </div>
                                <div className='product-livraison_wrapper'>
                                    <div className='livraison-inter' onClick={() => setShowShipping1(!showShipping1)} >
                                        <span> Livraison offerte </span>
                                        {!showShipping1 ? <HiOutlineChevronDown id="down" />
                                            : <HiOutlineChevronUp id="down" />}
                                    </div>
                                    <div className={!showShipping1 ? 'livraison-inter_infos' : 'livraison-inter_infos_show'}>
                                        <span>   Livraison offerte en France métropolitaine à partir de 150€ d'achats.</span>
                                    </div>
                                </div>
                            </div>
                            <div className='social-media-share'>
                                <FacebookShareButton url={url}>
                                    <span  >  <BsFacebook id="social-icon" title="Partager  sur Facebook" /> Partager </span>
                                </FacebookShareButton>
                                <PinterestShareButton url={url}>
                                    <span>    <FaPinterest id="social-icon" title="Épingler sur Pintrest" />  Épingler  </span>
                                </PinterestShareButton>
                                <TwitterShareButton url={url}>
                                    <span>      <BsTwitter id="social-icon" title="Tweeter sur Twitter" /> Tweeter</span>
                                </TwitterShareButton>
                            </div>
                            <div className='delivery'>
                                <span>     Expected delivery date between {current.toDateString()} and {next.toDateString()}  </span>
                            </div>
                            <div className='review'>
                                <p> Avis client</p>
                                <span id="not_review" > Pas d'avis actuellement</span>
                                <div className='review_button' onClick={() => setReviewShow(!reviewShow)}> Laisser Votre avis  </div>
                                <div className={reviewShow ? 'review_details' : 'review_hide'}>
                                    <div className='write'>  <span> ÉCRIRE VOTRE AVIS </span> </div>

                                    {!endRequest ?
                                        <>{errorReview &&
                                            <div className='review_box_error'>
                                                <span> Vérifier les champs </span>
                                            </div>}
                                            <div className='review_box'>
                                                <span> Nom </span>
                                                <input id={errorReview && "error"} name="ReviewName" placeholder='Saisir votre nom' onChange={handleChnage} />
                                            </div>
                                            <div className='review_box'>
                                                <span> Email </span>
                                                <input id={errorReview && "error"} name="Email" placeholder='Jhon.smith@example.com' onChange={handleChnage} />
                                            </div>
                                            <div className='review_box'>
                                                <span> TITRE AVIS </span>
                                                <input id={errorReview && "error"} name="Title" placeholder='Donnez un titre à votre avis' onChange={handleChnage} />
                                            </div>
                                            <div className='review_box'>
                                                <span> CONTENU DE L'AVIS (1500) </span>
                                                <textarea id={errorReview && "error"} name="Content" placeholder='Ecrire votre commentaire ici' onChange={handleChnage} />
                                            </div>
                                            <div onClick={submitReview} className={!loadReview ? 'review_button' : 'review_button_load'} >
                                                {loadReview
                                                    ? <AiOutlineLoading3Quarters id="load" />
                                                    : " Valider avis "
                                                }
                                            </div>
                                        </>
                                        :
                                        <div className="response" >  <span id="response"> Nous vous remercions pour votre avis ! </span>  </div>
                                    }
                                </div>


                            </div>
                        </form>


                    </div>
                </div>
            </div>


        </div>
    )
}

export default Singlproduct
