import React from 'react'
import Footer from '../../components/Footer/Footer'
import { LoginContext } from '../../Context/LoginContext/LoginContext'
import Nav_top from '../../Nav_top/Nav_top'
import { useContext } from 'react'
import './Account.scss'
import { logout } from '../../Context/LoginContext/LoginActions'
import { useNavigate } from 'react-router-dom'
import { useState , useEffect } from 'react'
import Commandes from './Commandes/Commandes'

const Account = ({openBag , rates , setOpenBag  }) => {
    const {dispatch } = useContext(LoginContext)
    const navigate = useNavigate()

    const [infos , setInfos] = useState()

    useEffect(() => {
     setInfos(JSON.parse(localStorage.getItem('login_user')))
    }, [infos])


  
  
  return (
    <div className='account'>
        <div className='navbar' id="navbar"  >
          <Nav_top rates={rates} openBag={openBag} setOpenBag={setOpenBag} />  
         </div>
         <div className='login_wrraper'>
            <div className='login_container'>
                    <span> MON COMPTE </span>
                    <button className='logout' onClick={()  =>{  dispatch(logout()); navigate('/')} } > se déconnecter   </button>
                    <div className='login_body'>
                        <div className='login_body_left'>
                            <h2> HISTORIQUE DES COMMANDES </h2>
                            <span> Vous n'avez pas placé de commande à ce jour. </span>
                            <Commandes/>
                        </div>
                    
                         <div className='login_body_right'>
                            <h5> DÉTAILS DU COMPTE </h5>
                            <div style={{display:"flex" , flexDirection:"column" , gap:"10px"}}>
                                <span className='name'> {  infos?.others?.FName} { infos?.others?.LName}  </span>
                                <span className='adress'> {  infos?.others?.Adress} </span>
                                <span className='link'> Voir les adresses(1) </span>
                            </div>
                        </div>
                
                    </div>
            </div>
         </div>
         <Footer/>

    </div>
  )
}

export default Account
