import React from 'react'
import Footer from '../../components/Footer/Footer'
import Nav_top from '../../Nav_top/Nav_top'
import './Register.scss'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useEffect , useState } from 'react'
import { CreateRegisterFailure, CreateRegisterStart, CreateRegisterSuccess } from '../../Context/RegisterContext/RegisterActions'
import axios from 'axios'
import { useContext } from 'react'
import { RegisterContext } from '../../Context/RegisterContext/RegisterContext'
import { CreateLoginFailure, CreateLoginStart, CreateLoginSuccess } from '../../Context/LoginContext/LoginActions'
import { LoginContext } from '../../Context/LoginContext/LoginContext'
import  { useRef } from 'react';
import {GoPrimitiveDot} from "react-icons/go"
import emailjs from '@emailjs/browser';
const Register = ({  openBag , setOpenBag ,rates}) => {

  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [registerInfos , setRegisterInfos] = useState([])
  const [error , setError] = useState(false)
  const [registerSuccess , setRegisterSuccess] = useState(false)
  const [emailInvalid , setEmailInvalid] = useState(false)
  const [showMessage , setShowMessage] = useState(false)
  const [passwordInvalid , setPasswordInvalid] = useState(false)
  const [allInvalid , setAllInvalid] = useState(false)

  const minLengthRegExp   = /.{8,}/;
  const minLengthPassword =   minLengthRegExp.test(registerInfos.Password);

  

  const navigateLogin = () => {
    navigate('/account/login')
  }

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth" , // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  const handleChnage = (e) => {
    let  value = e.target.value;
    setRegisterInfos({...registerInfos , [e.target.name]:value})
  }

  // check if the Email is valid
function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

useEffect(() => {
  if (!isValidEmail(registerInfos?.Email)) { // si l'email est valid on continue le processus 
 
      setEmailInvalid(true)
  }
  else {
    setEmailInvalid(false)
  }
     if(!minLengthPassword) {
      setPasswordInvalid(true)
     }
     else {
      setPasswordInvalid(false)
     }
     if(sizeInfos === 5) {
      setAllInvalid(false)
     }
     else {
      setAllInvalid(true)

     }
    
  
}, [registerInfos?.Email , registerInfos?.Password , sizeInfos])


// pour verifier si tous les champs sont rempli
var sizeInfos = Object.keys(registerInfos).length;


  // the context og register
  const user = useContext(RegisterContext).dispatch

  // the function of create Register
  const createRegister = async (register , dispatch) =>{
    
    user(CreateRegisterStart());
    try {
      setError(false)

        if(!emailInvalid && !passwordInvalid  &&( sizeInfos === 5)) { // if the email is valid we do ...
          const res = await axios.post("https://caftannsapi-6.onrender.com/api/register", register);
          user(CreateRegisterSuccess(res.data))
          setRegisterSuccess(true)
        }
        setError(false)

    }
    catch(err) {
      setError(true)

      user(CreateRegisterFailure())
    }

}

/// the context of Login
const  {dispatch} = useContext(LoginContext);


/// the fonction of create Login
 const createLogin =   async (login , dispatch) =>{
        dispatch(CreateLoginStart());
        try {
            setError(false)

            const res = await axios.post("https://caftannsapi-6.onrender.com/api/login", login);
                          dispatch(CreateLoginSuccess(res.data))
                          navigate('/account')
                          setError(false)
            
        }
        catch(err) {
            setTimeout(() => {
                setError(true)
            }, 1000);
       
            dispatch(CreateLoginFailure())
        }
  }
const submitAccount = ()  => { 
 // createLogin(registerInfos  , loginUser)
  createRegister(registerInfos  , user)
  setShowMessage(true)
    
}

// send an email to the user with welcome message
const sendEmail = (e) => {
  e?.preventDefault()
  let name = document.getElementById('F_name')?.value  
  let email =  document.getElementById('email')?.value 

  const contactParams = {
    to_name:name ,
    reply_to:email,
  }

emailjs.send('service_g2e72vs', 'template_god27de', contactParams , "QI2BMzI5FZC3Z0YVY")
  .then(function(response) {
     console.log('SUCCESS!', response.status, response.text);
  }, function(error) {
     console.log('FAILED...', error);
  });

}
/// end email ///

/// if the register is done // we gonna login automaticly 
useEffect(() => {
  if(registerSuccess) {
    createLogin(registerInfos  , dispatch)
  }
  
}, [registerSuccess])

// and send the email to the user

if(registerSuccess) {
  sendEmail()
}



const resetErrorEmail = () =>{

  setEmailInvalid(false)
 
}



console.log(sizeInfos)

  return (
    <div className='register'>
            <div className='navbar' id="navbar"  >
                <Nav_top  rates={rates} openBag={openBag} setOpenBag={setOpenBag} />  
            </div>
            <div className='register_wrapper' >
                <div className='register_container'>
                    <span> CRÉER UN COMPTE </span>
          {(( emailInvalid || passwordInvalid || error || allInvalid) && showMessage) ?
                    <div className='input_items_error'>
                      { emailInvalid && showMessage &&  <span>  <GoPrimitiveDot/>   E-mail invalid. </span>}
                      { passwordInvalid && showMessage &&  <span>  <GoPrimitiveDot/>  Au moins 8 caractères minimum . </span>}
                      { allInvalid && showMessage &&  <span>  <GoPrimitiveDot/> Tous les champs doivent etre rempli. </span>}
                       {error && showMessage  &&  <span>  <GoPrimitiveDot/> utilisateur est déja existant. </span>}
                   </div>
                   : null
                   }
                    <div className='input_items'>
                        <label>  PRÉNOM  </label>
                        <input required type="text" id="Fname" onChange={handleChnage} name='FName' />
                    </div>
                    <div className='input_items'>
                        <label>  Nom  </label>
                        <input required type="text" id="Lname"  onChange={handleChnage} name="LName"/>
                    </div>
                      <div onClick={resetErrorEmail} className='input_items'>
                        <label>  mail  </label>
                        <input required type="email" id="email" onChange={handleChnage} name="Email" />
                    </div>
                    <div className='input_items'>
                      <label>  MOT DE PASSE  </label>
                      <input required type="password" onChange={handleChnage} name="Password" />
                  </div>
                  <div className='input_items'>
                      <label>  Adresse  </label>
                      <input required type='text' onChange={handleChnage} name="Adress" />
                    </div>
                <button onClick={submitAccount} className='button'> créer </button>
                <span className='reject' onClick={navigateLogin}> Se connecter</span>
                    
                </div>
            </div>
            <Footer/>

            
    </div>
  )
}

export default Register
