import { createContext } from "react";
import { useReducer } from "react";
import LoginReducer from "./LoginReducer";
import { useEffect } from "react";

const INIT_STATE = {
    login :  JSON.parse(localStorage.getItem('login_user')) || [],
    isFetching : false,
    error:false
}

export const LoginContext = createContext(INIT_STATE);

export const LoginContextProvider = ({children})  => {

    const [state , dispatch] = useReducer(LoginReducer , INIT_STATE);

    useEffect(() => {
        localStorage.setItem('login_user' , JSON.stringify(state?.login))
    }, [state?.login])
    
    return (
        <LoginContext.Provider
        value={{
            login : state?.login,
            isFetching: state?.isFetching,
            error: state?.error,
            dispatch ,
        }}
        >
        {children}
        </LoginContext.Provider>    

    );


}