

export const CreateRegisterStart = () => ({

    type : "CREATE_REGISTER_START",

})
export const CreateRegisterSuccess = (register) => ({

    type : "CREATE_REGISTER_SUCCESS",
    payload : register

})
export const CreateRegisterFailure = () => ({

    type : "CREATE_REGISTER_FAILURE",

})
export const logoutUser = () => ({
    type: "LOGOUT_USER",
  });