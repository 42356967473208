import React, { useContext } from 'react'
import '../Search.scss'
import { BiShow } from 'react-icons/bi'
import { BsBag } from 'react-icons/bs'
import { useState } from 'react'
import {  useSearchParams } from 'react-router-dom'
import  {useEffect} from 'react'
import {VscListFilter} from "react-icons/vsc"
import '../../Search/ProductItem/ProductItem.scss'
import { BasketContext } from '../../Context/BasketContext/Basket'
import { Link } from 'react-router-dom'


const ProductItem = ({  openShop , setOpenShop, searchProduct , ordredProduct , setValueOfOption , valueOfOption   , selectedFilter , setSelectedFilter  , scroll  , openBag , setOpenBag , rates , selectedCurrency}) => {
    const [imageSelected , setImageSelected] = useState("");
    const [idSelected , setIdSelected] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [idImageSelected, setIdImageSelected] = useState("")
    const [quantityZero , setQuantityZero] = useState(false)
    const [show , setShow] = useState(false)

    const handleChangeOption = (e) => {
        setValueOfOption(e.target.value)

    }



    useEffect(() => {
        searchParams.set("sort_by", valueOfOption );
        setSearchParams(searchParams);
    }, [valueOfOption])

    const basket = useContext(BasketContext)
    const quantityValue = 1


let array = []
let newSize = []
let newSizeId = []
var newQuantity = []
let CustomArray = []



useEffect(() => {
    (ordredProduct != null ? ordredProduct : searchProduct )?.map((items , index) => {
   items.productDetails.map((productDetails , i) => {
       if(i === 0) {
           productDetails.details.map((details , i) => {
           if(details._id === idImageSelected && idSelected === items._id ) {
               details.variables.map((variables , i) => {
                CustomArray.push(variables.quantity)
                   for (let j = 0; j<CustomArray.length; j++) {
                       if (CustomArray[j] > 0) {
                              setShow(false)
                              break;
                   }
                   else {
                    setShow(true)
                   }
               }
           })
       }
           })
       }
   })
})
           
}, [idImageSelected , show])

const url = window.location.href



return (
            <div className='collection-grid' style={scroll > 120 ?{marginTop:"190px" } : {marginTop:"0px"} } >
             
                        <div className='collection-filter'>
                         <div className="number_results">
                            <span> {(searchProduct )?.length} resultats </span>
                    </div>
                    <div className="filter-section" onClick={() =>  setSelectedFilter(true)}>
                           <VscListFilter id="icon"/>
                            <span> Filter </span>
                    </div>
                        <div className='filter-sort'>
                            <select    onChange={handleChangeOption}>
                                <option   value="pertinence">    Pertinence   </option>
                                <option  value="price-ascending">  Prix : faible à élevé  </option>
                                <option  value="price-descending"> Prix : élevé à faible </option>
                            </select>
                        </div>
                    
                    </div>
                    <div className="grid_uniform">
                    { (ordredProduct != null ? ordredProduct : searchProduct )?.map((items , index) => (
                        <div className='grid_card'  key={index}>
                                <div className='product-images'  >
                                <Link  to= {`/collections/${items.produitCategorie}/products/${items.produitTitre}?_id=${items._id}`} className='link-product_image' style={{marginTop:"15px"}}> 
                                    <img alt={`${items?.produitCategorie} ${'Ns Caftan'} ` } src={ (idSelected === items?._id)  && imageSelected ? imageSelected :  items?.produitPrincipleImage  } id="image1" /> 
                                  <img alt={`${items?.produitCategorie} ${'Ns Caftan'} ` } src={ (idSelected === items?._id && idSelected != "" )? imageSelected :  items?.produitSecondImage } id="image2" />

                                 { (show && items?._id === idSelected ) && setQuantityZero ?   <div className="soldOut"> épuisé  </div> : ""}

                                </Link>
                                    <div className="product-card_overlay">
                                        <Link to={{
                                            search: window.location.search
                                        }}   preventScrollReset={true} state={{ items  }}  className="card-icons" onClick={() =>  setOpenShop(true)} >     <li data-title="quickShop"><BiShow id="icon" /> </li>   </Link>
                                        <div className="card-icons-shop">   
                                        <li data-title="Ajouter au panier">
                                            <BsBag  id="icon" 
                                                onClick={() => { 
                                                    items.productDetails.map((productDetails , i) => {
                                                        if(i === 0) {
                                                            productDetails.details.map((details , i) => {
                                                                
                                                            if(details._id === idImageSelected && idSelected === items._id ) {
                                                                details.variables.map((variables , i) => {
                                                                    array.push(variables.quantity)
                                                                    for (let j = 0; j<array.length; j++) {
                                                                        if (array[j] > 0) {
                                                                              setOpenBag(true) ;
                                                                              newSize.push(variables.titre)
                                                                              newSizeId.push(variables._id)
                                                                              newQuantity.push(variables.quantity)
                                                                                    basket.addOneToBasket(
                                                                                        newSizeId[0],
                                                                                        details?._id,
                                                                                        quantityValue  ,
                                                                                        items.produitPrix,
                                                                                        details.url,
                                                                                        newSize[0],
                                                                                        newQuantity[0],
                                                                                        items.produitTitre,
                                                                                        details?.colorOption,
                                                                                        items._id
                                                                                    ) 
                                                                                break;
                                                                        }else {
                                                                            setQuantityZero(true)
                                                                            setOpenBag(false) ;
                                                                        }
                                                                    } 
                                                                        
                                                                })
                                                            } 
                                                            if(idSelected != items._id  && i== 0){
                                                                details.variables.map((variables , i) => {
                                                                    array.push(variables.quantity)
                                                                    for (let j = 0; j<array.length; j++) {
                                                                        if (array[j] > 0) {
                                                                             setOpenBag(true) ;
                                                                              newSize.push(variables.titre)
                                                                              newSizeId.push(variables._id)
                                                                              newQuantity.push(variables.quantity)
                                                                                    basket.addOneToBasket(
                                                                                        newSizeId[0],
                                                                                        details?._id,
                                                                                        quantityValue  ,
                                                                                        items.produitPrix,
                                                                                        details.url,
                                                                                        newSize[0],
                                                                                        newQuantity[0],
                                                                                        items.produitTitre,
                                                                                        details?.colorOption,
                                                                                        items._id
                                                                                    ) 
                                                                                break;
                                                                        }
                                                                        else {
                                                                            setQuantityZero(true)
                                                                            setOpenBag(false) ;
                                                                        }
                                                                    } 
                                                                 
                                                                    
                                                                })    
                                                            }
                                                            })
                                                        }
                                            
                                                    })
                                                } } 
                                                
                                        />
                                        </li>
                                        </div>
                                </div>
                                { items?.produitSale &&
                                    <div className='product-sale'>
                                        <span id="slae"> {items?.produitSale}% </span>
                                </div>}
                                </div>
                                
                                <div  className="product-card_info"> 
                                    <Link  to= {`/collections/${items.produitCategorie}/products/${items.produitTitre}?_id=${items._id}`}  className="product_title">
                                        <p> {items?.produitTitre} </p>
                                    </Link>
                                    <div className="product_price">
                                 <span id='actPrice'> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(items?.produitPrix * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>

                                 { items?.produitPrevPrix &&
                                    <span id="prevPrice"> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }{(items?.produitPrevPrix * rates ).toFixed(2)}  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>}
                                    </div>
                                    <div className='product-display_images'>
                                    
                                    {items.productDetails.map(detail => (
                                        detail.details.map((images , i) => (
                                        <img  alt={`${items?.produitCategorie} ${'Ns Caftan'} ` } key={i} src={images.url} onClick={() => {
                                            setImageSelected(images.url)
                                            setIdSelected(items?._id)
                                            setIdImageSelected(images?._id)
                                            
                                        } } 
                                        />
                                    ))
                    ))}
                                
                                    </div>
                                </div>
                                </div>
                                ))
                            }
                    </div>
    </div>
  )
}

export default ProductItem
