const OrderReducer = (state , action) => {


    switch(action.type) {
        case 'CREATE_ORDER_START': 
            return  {
                order : [],
                isFetching : true,
                error : false, 
            }
           
        case 'CREATE_ORDER_SUCCESS': 
            return  {
                order : action.payload,
                isFetching : false,
                error : false, 
            }

            case 'CREATE_ORDER_FAILURE': 
            return  {
                order : [],
                isFetching : false,
                error : true, 
            }
                  
         

    }

    return state

}

export default OrderReducer