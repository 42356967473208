import React from 'react'
import './Commande.scss'

const Commandes = () => {
  return (
    <div className='comamndes_historque'>
           {/* <div className='comamndes_container'>
                <div className='order_header' >
                        <p className='num_commande'>
                            Order #SGDJS56S
                        </p>
                        <p className='sub_title'>placé le 23 juin 2023 à 20h21</p>
                </div>
                <div className='products'>
                    <img src='https://cdn.dribbble.com/users/10882342/avatars/normal/be1899b393a2631deeb65288ddda11e3.jpeg?1655561908' alt=''/>
                    <span className='title_product' > Takchita sihame </span>
                    <span className='size' > Takchita sihame </span>
                    <span className='quantity'> 11 </span>
                    <span className='price'> 180.89£ </span>
                </div>
                <div className='products'>
                    <img src='https://cdn.dribbble.com/users/10882342/avatars/normal/be1899b393a2631deeb65288ddda11e3.jpeg?1655561908' alt=''/>
                    <span className='title_product' > Takchita sihame </span>
                    <span className='size' > Takchita sihame </span>
                    <span className='quantity'> 11 </span>
                    <span className='price'> 180.89£ </span>
                </div>
                <div className='sous_total'>
                   <span>Sous-total</span>
                   <span>280.89£</span>
                </div>
        </div>
        <div className='comamndes_container'>
                <div className='order_header' >
                        <p className='num_commande'>
                            Order #SGDJS56S
                        </p>
                        <p className='sub_title'>placé le 23 juin 2023 à 20h21</p>
                </div>
                <div className='products'>
                    <img src='https://cdn.dribbble.com/users/10882342/avatars/normal/be1899b393a2631deeb65288ddda11e3.jpeg?1655561908' alt=''/>
                    <span className='title_product' > Takchita sihame </span>
                    <span className='size' > Takchita sihame </span>
                    <span className='quantity'> 11 </span>
                    <span className='price'> 180.89£ </span>
                </div>
                
        </div> */}
    </div>
  )
}

export default Commandes
