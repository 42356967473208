import React from 'react'
import './Sidebar.scss'
import Slider from '@material-ui/core/Slider';
import { IoIosArrowDown } from "react-icons/io"
import { IoIosArrowUp } from "react-icons/io"
import { useState, useEffect } from 'react';
import { TfiPlus } from 'react-icons/tfi'
import { TfiMinus } from 'react-icons/tfi'
import white from '../../images/white.png'
import lila from '../../images/lila.png'
import black from '../../images/black.png'
import gray from '../../images/gray.png'
import red from '../../images/red.png'
import yellow from '../../images/yellow.png'
import green from '../../images/green.png'
import blue from '../../images/blue.png'
import beige from '../../images/beige.png'
import fuschia from '../../images/fuschia.png'
import mustard from '../../images/mustard.png'
import bordeaux from '../../images/bordeaux.png'
import lightBlue from '../../images/lightBlue.png'
import pistache from '../../images/pistache.png'
import oldPink from '../../images/oldPink.png'
import orange from '../../images/orang.png'
import purple from '../../images/purple.png'
import darkBlue from '../../images/darkBlue.png'
import pink from '../../images/pink.png'
import taupe from '../../images/taupe.png'
import { TfiClose } from "react-icons/tfi"
import './Sidebar.scss'


const Sidebar = ({ color, scroll, setColor, setColorArray, colorArray, setSearchParams, setHighestValue, rates, selectedCurrency, value, setValue, highestValue, availability, setAvailability, selectedFilter, setSelectedFilter }) => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [colorSelected, setColorSelected] = useState(false)


    const rangeSelector = (event, newValue) => {
        setValue(newValue);
        console.log(newValue)
    };


    const allColors = [
        { color: "lila", img: lila }, { color: "white", img: white }, { color: "gray", img: gray }, { color: "black", img: black }, { color: "red", img: red }
        , { color: "yellow", img: yellow }, { color: "green", img: green }, { color: "blue", img: blue }, { color: "beige", img: beige }, { color: "fuschia", img: fuschia },
        { color: "bordeaux", img: bordeaux }, { color: "mustard", img: mustard }, { color: "oldPink", img: oldPink }, { color: "pistache", img: pistache }, { color: "lightBlue", img: lightBlue },
        { color: "orange", img: orange }, { color: "purple", img: purple }, { color: "darkBlue", img: darkBlue },
        { color: "pink", img: pink }, { color: "taupe", img: taupe }

    ]


    return (
        <>
            <div className='hide_sideBar' style={selectedFilter ? { display: "flex" } : { display: "none" }} onClick={() => setSelectedFilter(false)}>
            </div>
            <div className='collection-sidebar' style={selectedFilter ? { transform: " translateX(350px)" } : { transform: " translateX(0px)" }} >
                <form className='filter-form'>
                    <div className='filter-block' style={selectedFilter ? { animation: "animate-acceuil-link 0.7s ease-in-out" } : null}>
                        <span> Filter </span>
                        <TfiClose id="icon-close" onClick={() => setSelectedFilter(false)} />
                    </div>

                    <div className=' collection-sidebar__group' style={selectedFilter ? { animation: "animate-acceuil-link 1s ease-in-out" } : null} >
                        <div className='filter_button' onClick={() => setOpen(!open)}>
                            <span>  Disponibilité </span>
                            {!open ?
                                <IoIosArrowDown id="icon-down" />
                                :
                                <IoIosArrowUp id="icon-down" />
                            }
                        </div>
                        <div className={!open ? 'filter_disponibilte' : 'filter_disponibilte_hide'} >
                            <ul className='tag-list'>
                                <li>
                                    <label className='checkbox_wrapper' onClick={() => setAvailability(prev => prev === 0 ? 1 : 0)} >
                                        <input type="checkbox"></input>
                                        <span className='tag-list'> En stock </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='bar' style={selectedFilter ? { animation: "animate-acceuil-link 1s ease-in-out" } : null} >   </div>

                    <div className=' collection-sidebar__group' style={selectedFilter ? { animation: "animate-acceuil-link 1s ease-in-out" } : null}>
                        <div className='filter_button' onClick={() => setOpen1(!open1)}>
                            <span>  PRIX </span>
                            {!open1 ?
                                <IoIosArrowDown id="icon-down" />
                                :
                                <IoIosArrowUp id="icon-down" />
                            }
                        </div>
                        <div className={!open1 ? 'filter_disponibilte' : 'filter_disponibilte_hide'} >
                            <div className='collection-fliter_price'>
                                <div className='checkbox_wrapper_price'>
                                    <div className='price_range'>
                                        <div className='price-range_display'>
                                            <span>
                                                {selectedCurrency === "USD" ? "$"
                                                    : selectedCurrency === "AED" ? "Dhs."
                                                        : selectedCurrency === "GBP" ? "£"
                                                            : selectedCurrency === "QAR" ? "QAR"
                                                                : null}  {(value[0] * rates).toFixed(0)}
                                                {selectedCurrency === "EUR" ? "€"
                                                    : selectedCurrency === "MAD" ? "dh"
                                                        : null}
                                            </span>
                                            <span>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs." : selectedCurrency === "GBP" ? "£" : selectedCurrency === "QAR" ? "QAR" : null}  {(value[1] * rates).toFixed(0)} {selectedCurrency === "EUR" ? "€" : selectedCurrency === "MAD" ? "dh" : null} </span>
                                        </div>
                                        <div className='price-range_slider'>
                                            <Slider
                                                value={value}
                                                min={0}
                                                max={400}
                                                onChange={rangeSelector}
                                                valueLabelDisplay="auto"
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="color_filter" style={selectedFilter ? { animation: "animate-acceuil-link 1.3s ease-in-out" } : null}>
                        <div className='filter_button' onClick={() => setOpen2(!open2)}>
                            <span>  COLOR   </span>
                            {!open2 ?
                                <TfiMinus id="icon-down" />
                                :
                                <TfiPlus id="icon-down" />
                            }
                        </div>

                        <div className={!open2 ? "colors_disponibilities" : "colors_disponibilities_hide"}>

                            {allColors.map((item, i) => (
                                <div
                                    key={i} className="advenced-filter" onClick={() => {
                                        if (colorArray.includes(item.color) === false) {
                                            setColorArray(oldArray => [...oldArray, item.color])
                                        } else {
                                            setColorArray((current) =>
                                                current.filter((colors) => colors !== item.color)
                                            )
                                        }
                                    }
                                    }
                                >
                                    <li style={colorArray.includes(item.color) === true ? { borderColor: "rgba(0, 0, 0, 0.7)" } : null} data-title={item.color}


                                    >   <img src={item.img} alt='Color' />    </li  >
                                </div>
                            ))

                            }
                        </div>


                    </div>

                </form>
            </div>


        </>
    )
}

export default Sidebar
