

export const CreateNewsLetterStart = () => ({

    type : "CREATE_NEWSLETTER_START",

})
export const CreateNewsLetterSuccess = (news) => ({

    type : "CREATE_NEWSLETTER_SUCCESS",
    payload : news

})
export const CreateNewsLetterFailure = () => ({

    type : "CREATE_NEWSLETTER_FAILURE",

})