import React from 'react'

import '../Paginations/Paginations.scss'
const Paginations = ({NbrPages , currentPage , setCurrentPage}) => {

  const generatedPages =  [] ;

    for(let i = 0 ; i < Math.ceil(NbrPages) ; i++) {
      generatedPages.push(i);
    }
    


  return (
    <nav className='paginate'>
        <ul className='pagination'>
            { generatedPages.map(number => (
                <li key={number} className="page-item" style={{display:"flex" }}>
                       <p  style={currentPage ==( number + 1 )? { color:"lightgray" } : null} onClick={() => setCurrentPage(number+1)} href='!#' className='page_link'>
                            {number +1}
                       </p>
                </li>
            ))
            }
        </ul>
        
    </nav>
  )
}

export default Paginations
