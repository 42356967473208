

export const CreateContactStart = () => ({

    type : "CREATE_CONTACT_START",

})
export const CreateContactSuccess = (contact) => ({

    type : "CREATE_CONTACT_SUCCESS",
    payload : contact

})
export const CreateContactFailure = () => ({

    type : "CREATE_CONTACT_FAILURE",

})
