const LoginReducer = (state , action) => {


    switch(action.type) {
        
        case "LOGOUT":
            return {
              login: [],
              isFetching: false,
              error: false,
            };

        case 'CREATE_LOGIN_START': 
            return  {
                login : [],
                isFetching : true,
                error : false, 
            }
           
        case 'CREATE_LOGIN_SUCCESS': 
            return  {
                login : action.payload,
                isFetching : false,
                error : false, 
            }

            case 'CREATE_LOGIN_FAILURE': 
            return  {
                login : [],
                isFetching : false,
                error : true, 
            }
         

    }

    return {...state}

}

export default LoginReducer