import React, { useState } from 'react'
import Footer from '../../components/Footer/Footer'
import Nav_top from '../../Nav_top/Nav_top'
import './Faq.scss'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'

const Faq = ({openBag , setOpenBag , rates}) => {
    const {pathname} = useLocation()
    useEffect(() => {
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth" , 
        });
      }, [pathname]);

      const [openResponse , setOpenResponse] = useState(false)
      const [openResponse1 , setOpenResponse1] = useState(false)
      const [openResponse2 , setOpenResponse2] = useState(false)
      const [openResponse3 , setOpenResponse3] = useState(false)


  return (
    <div className='faq_page'>
         <Nav_top openBag={openBag} rates={rates} setOpenBag={setOpenBag}  />
         <div className='faq_wrapper'> 
            <div className='faq_container'> 
            <span className='title'>  FAQ</span>
                <div className='faq_left'> 
                    <div className='faq_title_section'> 
                        <h4> QU'EST CE QU'UN UN CAFTAN ? </h4>
                        <span>
                            Le caftan marocain est un vêtement ample, la coupe en forme T, que les femmes portent pour diverses occasions : 
                            <li>Les caftans décontractés que l'on met à la maison sont courts et sans ceinture. Ils peuvent être imprimé, 
                            unis, légèrement brodé ou perlé.</li>
                            <li>  Les caftans officiels sont luxueux et riches. Ils sont souvent utilisés lors des grands événements familiaux, les mariages... C'est une sorte
                             de robe chemise longue, en une ou deux pièces, accessoirisée par une ceinture pour marquer la taille, ce qui rend la tenue extrêmement féminine
                              et élégante.</li>
                      </span>
                      <span  className='question' onClick={() => setOpenResponse(!openResponse) } >{openResponse ?  <BiChevronUp className="icon" /> :  <BiChevronDown className="icon" />  }  Quels sont les différents types de caftans ? </span>
                      <div className={!openResponse ? 'response' : 'response_open'} >
                          <div className='response_items'>
                          <span> <span style={{fontWeight:900}} >  Djellaba marocaine :</span> C'est une sorte de robe longue portée par la femme marocaine pour sortir à l'extérieur : faire des
                                courses, visite officiel de la famille, cérémonie de baptèmes, demandes en mariages... C'est un vêtement oversize aux épaules larges, avec une capuche. La djellaba marocaine est un vêtement pour femme très pratique et
                                facile à porter comme un manteau l'hiver au dessus des vêtements, ou seule en été. Acheter en ligne votre prochaine djellaba moderne marocaine <Link className='link' to={"/collections/jellaba"} > en cliquant ici </Link>
                                <br/>  <br/> 
                              <span style={{fontWeight:900}} >   Gandoura ou Gandora : </span>C'est un vêtement connu en occident sous le nom de Kaftan dubai ou Beach kaftan ou Cover up. C'est une robe fluide et longue généralement en mousseline ou en crêpe. 
                                La Gandoura ou Kaftan marocain pour 
                                femme est un vêtement très élégant portée par des grandes célébrités internationales qui en ont fait la promotion à l'étranger. Acheter en ligne votre gandoura ou dubai Kaftan soirée  <Link className='link' to={"/collections/gandoura"} > en cliquant ici </Link>
                                <br/>  <br/> 
                              <span style={{fontWeight:900}} >     Caftan marocain :</span> le caftan est connaissable par sa coupe traditionnelle sous forme de T. Le caftan marocain pour femme se porte sans ceinture à la maison comme vêtement d'intérieur pour recevoir
                                 sa famille, lors d'une soirée de diner. C'est un vêtement chic et décontracté. La collection 2020 de caftan marocain de la marque finezine  permet à la femme de se sentir chic, raffinée
                                 et belle chez elle de manière décontractée. Le caftan peut être réalisé de différentes matières : caftan en brocard, caftan en soie. La coupe du caftan est également ajustée pour
                                  un caftan moderne  <Link className='link'   to={"/collections/caftan"} > en cliquant ici </Link>
                                <br/>  <br/> 
                              <span style={{fontWeight:900}} >  Takchita marocaine :</span> C'est une superposition de deux caftans ajustés par une ceinture au niveau de la taille. La takchita pour femme marocaine est un vêtement pour qui se porte exclusivement lors
                                 des cérémonies de mariage. La takchita est portée par la mariée lors 
                                du mariage marocain ainsi que l'ensemble des invités. Lors de la soirée de mariage marocain, toutes les femmes portent une takchita. Acheter en ligne votre caftan ou takchita pour mariage
                                <Link className='link' to={"/collections/takchita"} > en cliquant ici </Link>
                                <br/>  <br/> 

                           </span>
                          </div>
                      </div>
                      <span  className='question' onClick={() => setOpenResponse1(!openResponse1) } >{openResponse1 ?  <BiChevronUp className="icon" /> :  <BiChevronDown className="icon" />  }  Comment contacter nos services ? </span>
                      <div className={!openResponse1 ? 'response' : 'response_open'} >
                      <div className='response_items'>
                      <span>  Nous vous conseillons vivement lors de la réception, de bien contrôler en présence du livreur l’état général du colis. En cas d’anomalie, vous disposez de sept (7) jours 
                             ouvrables à compter du jour de la réception de votre commande pour contacter notre Service Client afin qu’un conseiller puisse valider et enregistrer votre demande de retour.
                            <br/>  <br/> 
                            Merci de nous renvoyer les articles intacts et complets dans leur emballage d'origine (avec les étiquettes comportant leurs références), dans un délai de 7 jours après réception de votre colis.
                            <br/>  <br/> 
                          <li>  Pour un problème de taille, vous avez 7 jours, après réception du produit, pour le renvoyer. Vous devez en informer le service client en l'appelant ou en lui envoyant un email.  </li>
                          <li>   Pour un problème de modèle, vous avez 48h, après réception du produit, pour le renvoyer. Vous devez en informer le service client en l'appelant ou en lui envoyant un email. </li>
                          <li>   Pour toutes autres raisons, vous devez contacter le service client pour leur expliquer les raisons de votre renvoie d'articles. Vous avez un délais maximal de 7 jours. </li> 
                            <br/>  <br/> 
                            Il est à noter que la marque Finezine n'effectue pas de remboursement pour un retour d'article. Vous bénéficiez d'un avoir utilisable pendant 3 mois sur notre site. Au-delà de ces délais, nous ne pourrons plus accepter de commande avec cet avoir. 
                            <br/>  <br/> 
                            Conformément aux dispositions légales, vous pouvez user de votre droit de rétractation de sept (7) jours à compter de la validation de votre commande pour annuler votre commande ou pour nous retourner, à vos frais, les articles qui ne
                             vous satisfont pas à condition qu’ils n’aient pas été dégradés et qu'ils soient
                             restitués dans leurs emballages d'origine. Vous serez remboursé dans un délai de quinze (15) jours à compter de la réception de votre colis de retour, par un avoir d’un montant égal à votre commande et valable sur l’ensemble des produits du site.
                             <br/>  <br/> 

                       </span>
                      </div>
                  </div>
                      <span  className='question' onClick={() => setOpenResponse2(!openResponse2) } >{openResponse2 ?  <BiChevronUp className="icon" /> :  <BiChevronDown className="icon" />  }  Comment retourner un article ? </span>
                      <div className={!openResponse2 ? 'response' : 'response_open'} >
                      <div className='response_items'>
                      <span>  Pour retourner un article, nous vous remercions de contacter notre Service Client par téléphone ou par Email.
                            <br/>  <br/> 
                            Les articles doivent être retournés,<span style={{fontWeight:"700"}}>  à vos frais:</span> 
                            <br/>  <br/> 
                          <li>  Dans leur conditionnement et emballage d’origine à l’adresse indiqué ci-dessous ou communiquée par le Service Client </li>
                          <li> Dans un état neuf, sans dégradations, et propre à la revente </li>
                          <li>  Les étiquettes doivent être intactes et accrochées aux produits. </li> 
                            <br/>  <br/> 
                            Afin d'assurer un traitement rapide de votre retour, pensez à insérer dans votre colis l’Email de retour qui vous sera envoyé par le Service Client. Si vous ne disposez pas
                             d’imprimante veuillez inscrire sur papier libre votre numéro de commande, numéro d’autorisation de retour ainsi que les codes des articles à retourner.
                            <br/>  <br/> 
                           
                       </span>
                      </div>
                  </div>
                      <span  className='question' onClick={() => setOpenResponse3(!openResponse3) } >{openResponse3 ?  <BiChevronUp className="icon" /> :  <BiChevronDown className="icon" />  }  Comment suis je rembourser ? </span>
                      <div className={!openResponse3 ? 'response' : 'response_open'} >
                      <div className='response_items'>
                      <span>Le remboursement est un avoir que vous pouvez utiliser sur le site pendant 3 mois pour de prochains achats. 
                            Pas de papiers et donc pas de pertes, retrouvez 24h/24, tous vos avoirs et bons d’achats sur votre espace client Finezine, dans la rubrique « Votre Compte ».
                            Généralement, les remboursements sont traités dans un délai maximum de quinze (15) jours ouvrables suivant la réception de vos articles.
                            <br/>  <br/> 
                       </span>
                      </div>
                  </div>

                    </div>
         
                   <div className='faq_title_section'  > 
                      <h4 style={{fontSize:"16px"}}> LE SERVICE CLIENT À VOTRE DISPOSITION !  </h4>
                      <span className='long'>  N'hésitez pas à nous envoyer un message via whatsapp avec le lien ou la photo de votre commande pour avoir
                                               la confirmation du délai de livraison de votre panier au numéro suivant : +33771553049  </span>
                     <span>     Vous pouvez nous écrire 7j/7, 24h/24 :  <a className='contact' href="mailto: nsCollection.tls@gmail.com" > nsCollection.tls@gmail.com ! </a></span>
                    </div>
                </div>
            </div>
         </div>
         <Footer/>
    </div>
  )
}

export default Faq
