import React from 'react'
import './Related.scss'
import { useEffect , useState , useContext } from 'react'
import axios from "axios"
import { BsBag, BsInstagram } from 'react-icons/bs'
import { BiShow } from 'react-icons/bi'
import {CgArrowLongLeft} from "react-icons/cg"
import { BasketContext } from '../../Context/BasketContext/Basket'
import { Link } from 'react-router-dom'

const Related = ({setOpenBag , openBag , setOpenShop}) => {

  const [relatedProduct , setRelatedProduct] = useState([])
  const [productValue , setProductValue] = useState()
  const basket = useContext(BasketContext)
  const params = new URLSearchParams(window.location.search)
  const idValue = params.get('_id')

  useEffect( () => {

      axios.get(`https://caftannsapi-6.onrender.com/api/products/${idValue}/related`).
      then((response) => 
        setRelatedProduct(response.data)
      )
 
  }, [])

  const scrollRef = React.useRef(null);

  const Category = []

  
  relatedProduct?.map((product) => 
 {   Category.push(product?.produitCategorie)})



 const [selectedId , setSelectedId] = useState('');




   useEffect(() => {
  axios.get(`https://caftannsapi-6.onrender.com/api/products/${selectedId}` , )
    .then(response => {
      setProductValue(response.data)
    })
}, [selectedId])

const quantityValue = 1
let array = []
let newSize = []
let newSizeId =  [] 
let newQuantity = []


  return (
    <div className='related'>
        <div className='related_container'>
            <div className='page-width'> 
                <div className='section-header'> 
                    <h2>             VOUS ALLEZ SÛREMENT AIMER CES PRODUITS                </h2>
                </div>
                <div className="app__gallery-images">
                    <div className="app__gallery-images_container"   ref={scrollRef}>
                          {relatedProduct?.map((items , i) => (

                              <div key={i} className="app__gallery-images_card flex__center" >
                                <img src={items.produitPrincipleImage}   className="gallery_image"  alt={`${items?.produitCategorie}  ${items?.produitTitre}`}/>
                                <img src={items.produitSecondImage} alt={`${items?.produitCategorie}  ${items?.produitTitre}`} className="gallery_image1" />
                                <div className='infos_product' >
                                    <p> {items.produitTitre} </p>
                                    <span> {items.produitPrix}€ </span>
                                </div>
                                  <div className='list_icons'>
                                  <Link to={{
                                          search: window.location.search
                                      }}   preventScrollReset={true} state={{items}}  className="icon_bi"  >  
                                            <li data-title="quickShop" onClick={() => setOpenShop(true)} > 
                                                  <BiShow style={{color:"white"}} className='icon'/>  
                                              </li> 
                                </Link>
                                  <div  className="icon_bag" onClick={() => setSelectedId(items._id) } >   <li title data-title="Ajouter au panier"  onClick={() => { 
                                    setOpenBag(true) ;
                                    items.productDetails.map((productDetails , i) => {
                                          if(i === 0) {
                                            productDetails.details.map((details , i) => {
                                              if(i === 0) {
                                                  details.variables.map((variables , i) => {
                                                      array.push(variables.quantity)
                                                      for (let j = 0; j<array.length; j++) {
                                                          if (array[j] > 0) {
                                                                newSize.push(variables.titre)
                                                                newSizeId.push(variables._id)
                                                                newQuantity.push(variables.quantity)
                                                                      basket.addOneToBasket(
                                                                          newSizeId[0],
                                                                          details?._id,
                                                                          quantityValue  ,
                                                                          items.produitPrix,
                                                                          details.url,
                                                                          newSize[0],
                                                                          newQuantity[0],
                                                                          items.produitTitre,
                                                                          details?.colorOption,
                                                                          items._id
                                                                      ) 
                                                                  break;
                                                          }
                                                      } 
                                                          
                                                  })
                                              }
                                            })
                                          }

                                      })
                                    
                                  } } > 
                                  
                                  
                                  <BsBag id="icon" /></li> </div> 
                                  


                                  </div>
                              </div>
                          ))
                                    }
                    </div>
              </div>
              <div className='button_back'>
                  <div className='button_container'>
                     <CgArrowLongLeft id="icon" />
                     <Link to={`/collections/${Category[0]}`} >   <span> retour à {Category[0]}  </span></Link>
                  </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Related
