import React, { useContext } from 'react'
import { BsBag } from 'react-icons/bs'
import { VscListFilter } from 'react-icons/vsc'
import { Link } from 'react-router-dom'
import {BiShow} from "react-icons/bi"
import { useState } from 'react'
import './ListProduct.scss'
import { BasketContext } from '../../Context/BasketContext/Basket'
import { useEffect } from 'react'
const ListProduct = ({searchProduct , ordredProduct, setValueOfOption  , setSelectedFilter , setOpenBag  , setOpenShop , rates , selectedCurrency}) => {
    

    // changer la valeur de l'option 
    const handleChangeOption = (e) => {
        setValueOfOption(e.target.value)
    }

    const [imageSelected , setImageSelected] = useState('')
    const [idImageSelected , setIdImageSelected] = useState('')
    const [idSelected , setIdSelected] = useState('')
    const quantityValue = 1
    const [quantityZero , setQuantityZero] = useState(false)
    const [show , setShow] = useState(false)


    const basket = useContext(BasketContext)

    let array = []
    let newSize = []
    let newSizeId = []
    var newQuantity = []
    let CustomArray = []

    useEffect(() => {
        (ordredProduct != null ? ordredProduct : searchProduct )?.map((items , index) => {
       items.productDetails.map((productDetails , i) => {
           if(i === 0) {
               productDetails.details.map((details , i) => {
               if(details._id === idImageSelected && idSelected === items._id ) {
                   details.variables.map((variables , i) => {
                    CustomArray.push(variables.quantity)
                       for (let j = 0; j<CustomArray.length; j++) {
                           if (CustomArray[j] > 0) {
                                  setShow(false)
                                  break;
                       }
                       else {
                        setShow(true)
                       }
                   }
               })
           }
               })
           }
       })
    })
               
    }, [idImageSelected , show])
    


    console.log("searchProduct" , searchProduct )


  return (
    <div className='ListProduct' >
             
    <div className='collection-filter'>
     <div className="number_results">
        <span> {(searchProduct )?.length} resultats </span>
</div>
<div className="filter-section"  onClick={() =>  setSelectedFilter(true)}>
       <VscListFilter id="icon"/>
        <span> Filter </span>
</div>
    <div className='filter-sort'>
        <select    onChange={handleChangeOption}>
            <option   value="pertinence">    Pertinence   </option>
            <option  value="price-ascending">  Prix : faible à élevé  </option>
            <option  value="price-descending"> Prix : élevé à faible </option>
        </select>
    </div>

</div>


<div className="grid_uniform">
{  (  ordredProduct != null ? ordredProduct :  searchProduct )?.map((items , index) => (
    <div className='grid_card'  key={index} style={{marginTop:"15px"}}>
            <div className='product-images'  >
            <Link  to= {`/collections/${items.produitCategorie}/products/${items.produitTitre}?_id=${items._id}`} className='link-product_image' > 
                <img src={ ( idSelected === items?._id)  && imageSelected !== "" ? imageSelected :  items?.produitPrincipleImage  } id="image1"  alt={items?.produitCategorie} /> 
              <img src={ (idSelected === items._id && imageSelected !== "" )? imageSelected :   items?.produitSecondImage } id="image2" alt={items?.produitCategorie} />

              { (show && items?._id === idSelected ) && setQuantityZero ?   <div className="soldOut"> épuisé  </div> : ""}

            </Link>
                <div className="product-card_overlay">
                    <Link to={{
                        search: window.location.search
                    }}   preventScrollReset={true} state={{items}}  className="card-icons"  >  
                           <li data-title="quickShop" onClick={() => setOpenShop(true)} > 
                                <BiShow id="icon" /> 
                            </li> 
                    
                    </Link>
                    <div className="card-icons-shop">   
                    <li data-title="Ajouter au panier">
                        <BsBag  id="icon" 
                           onClick={() => { 
                               items.productDetails.map((productDetails , i) => {
                                    if(i === 0) {
                                        productDetails.details.map((details , i) => {
                                        if(details._id === idImageSelected && idSelected === items._id ) {
                                           details.variables.map((variables , i) => {
                                                array.push(variables.quantity)
                                                for (let j = 0; j<array.length; j++) {
                                                    if (array[j] > 0) {
                                                          setOpenBag(true) ;
                                                          newSize.push(variables.titre)
                                                          newSizeId.push(variables._id)
                                                          newQuantity.push(variables.quantity)
                                                                basket.addOneToBasket(
                                                                    newSizeId[0],
                                                                    details?._id,
                                                                    quantityValue  ,
                                                                   items.produitPrix,
                                                                    details.url,
                                                                    newSize[0],
                                                                    newQuantity[0],
                                                                    items.produitTitre,
                                                                    details?.colorOption,
                                                                   items._id
                                                                ) 
                                                            break;
                                                    }else {
                                                        setQuantityZero(true)
                                                        setOpenBag(false) ;
                                                    }
                                                } 
                                                    
                                            })
                                       } if(idSelected != items._id  && i== 0){
                                            details.variables.map((variables , i) => {
                                                array.push(variables.quantity)
                                                for (let j = 0; j<array.length; j++) {
                                                    if (array[j] > 0) {
                                                         setOpenBag(true) ;
                                                          newSize.push(variables.titre)
                                                          newSizeId.push(variables._id)
                                                          newQuantity.push(variables.quantity)
                                                                basket.addOneToBasket(
                                                                    newSizeId[0],
                                                                    details?._id,
                                                                    quantityValue ,
                                                                    items.produitPrix,
                                                                    details.url,
                                                                    newSize[0],
                                                                    newQuantity[0],
                                                                    items.produitTitre,
                                                                    details?.colorOption,
                                                                    items._id
                                                                ) 
                                                            break;
                                                    }
                                                    else {
                                                        setQuantityZero(true)
                                                        setOpenBag(false) ;
                                                    }
                                                } 
                                             
                                                
                                            })    
                                        }
                                        })
                                    }
                        
                                })
                            } 
                        } 
                    />
                    </li>
                    </div>
            </div>
            {items?.produitPrevPrix && <span className='sale'> {((100 - (items?.produitPrix * 100 )/ items?.produitPrevPrix)).toFixed(0)}%  </span>}
            </div>
            
            <div  className="product-card_info"> 
                <Link  to= {`/collections/${items.produitCategorie}/products/${items.produitTitre}?_id=${items._id}`}  className="product_title">
                    <p> {items?.produitTitre} </p>
                </Link>
                <div className="product_price">
                        <span id="actPrice">{selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(items?.produitPrix * rates ).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>
                     { items?.produitPrevPrix &&   <span id="prevPrice"> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }{(items?.produitPrevPrix * rates ).toFixed(2)}  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>}
                        
                </div>
                <div className='product-display_images'>
                
                {items.productDetails.map(detail => (
                    detail.details.map((images , i) => (
                    <img alt={items?.produitCategorie}  key={i} src={images.url} onClick={() => {
                           setImageSelected(images.url)
                            setIdSelected(items._id)
                            setIdImageSelected(images._id)

                    } } 
                    />
                ))
))}
                </div>
            </div>
            </div>
            ))
        }
</div>
    </div>
  )
}

export default ListProduct
