const RegisterReducer = (state , action) => {


    switch(action.type) {
        case "LOGOUT_USER":
            return {
            register: [],
            isFetching: false,
            error: false,
            };

        case 'CREATE_REGISTER_START': 
            return  {
                register : [],
                isFetching : true,
                error : false, 
            }
           
        case 'CREATE_REGISTER_SUCCESS': 
            return  {
                register : action.payload,
                isFetching : false,
                error : false, 
            }

            case 'CREATE_REGISTER_FAILURE': 
            return  {
                register : [],
                isFetching : false,
                error : true, 
            }
                  
         

    }

    return state

}

export default RegisterReducer