import React from 'react'
import { TfiClose } from 'react-icons/tfi'
import './Bag.scss'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { BasketContext } from '../../Context/BasketContext/Basket'
import { useContext , useEffect , useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid';
import {AiOutlineLoading3Quarters} from 'react-icons/ai'



const Bag = ({openBag , setOpenBag  , rates , selectedCurrency }) => {
    const basket = useContext(BasketContext)
    

        const hideBag = (e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpenBag(false)
        }
        
        const openBag1 = (e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpenBag(true)
        }
        const tableId = []
        const ColorId = []


        basket.items.map((item) => {
            tableId.push(item.id)
            ColorId.push(item.idColor)
        })


        const hideBag1 = (e) => {
            e.stopPropagation()
            setOpenBag(false)

}

    

    /// si la quantité déclarer par le client dépasse la quantité maximal en retourn la quantité déclaer égale quantMAx
    basket.items.map((item) => {

            if(item.quantity >  item.quantityMax) {
                item.quantity = item.quantityMax
            }

    })

    // mettre dans un tableau la quantité * price // pour faire le calcul total price
    const pricess = []
    basket.items.map((item) => {

        pricess.push(item.quantity*item.price)

    })

    

    let total = 0
    // total price
        for(let i = 0; i < pricess.length; i++) {

            total = total + pricess[i]
        }
        
      // create ORDER
       const [infos , setInfos] = useState({}) 
      const [userId , setUserId] = useState("")


    
    
  // get the userif exist
      useEffect(() => {
            setInfos(JSON.parse(localStorage.getItem('login_user')))
       }, [infos])
       // declare navigate 
       const navigate = useNavigate()

       // setUserId the value of U_ID in loval storage
       useEffect(() => {
        setUserId(JSON.parse(localStorage.getItem('U_ID')))
      }, [])

// crate the order and send values to local storage

      const [loader , setLoader] = useState(false)


      const CreateOrder = () => {
      
        const unique_id = uuid()
                if(infos?.others?._id) {
                    localStorage.setItem('U_ID', JSON.stringify(infos?.others?._id))
                }
     
                else {
                    if(!userId)  {
                     localStorage.setItem('U_ID', JSON.stringify(unique_id))
                     localStorage.setItem('U_IDV2', JSON.stringify(unique_id))
                }
           }
           setLoader(true)
         
          // to navigate in order stepper
          setTimeout(() => {
            const IdUser = infos?.others?._id
          
                if(!userId) {
                    if(infos?.others?._id) {
                        navigate(`/checkouts/${IdUser}}`)
                    }
                    else {
                        navigate(`/checkouts/${unique_id}}`)
                    }
                }
                else {
                    navigate(`/checkouts/${userId}}`)
                }
                setOpenBag(false)
                setLoader(false)
          }, 3000);

    
       
       
      }

     // check si l'utilisateur a été connecté pour chnager son UID guest to UID user
      useEffect(() => {
        if((userId !== infos?.others?._id &&  infos?.others?._id != undefined ) ) {
            localStorage.setItem('U_ID', JSON.stringify(infos?.others?._id))
        }
      }, [infos?.others?._id])
   
 
  return (
    
    <div  onClick={hideBag} className= { openBag === false ?"bag_page" : "bag_page_open" } id="bag_page">
        <div onClick={openBag1} className={openBag === false  ?'bag_container' : 'bag_container_open'}>

            <div className='bag_wrapper'>
                <div className={!openBag ? 'bag_header' : 'bag_header_show'}>
                   <span > Panier </span>
                   <TfiClose id="close" onClick={hideBag} />
                </div>
                { basket.items.length != 0 ? 
                <>
                <div  className='scroll_container' style={{overflowY:"auto" , maxHeight:"60%" , paddingRight:"30px"  , width:"100%"}} >
            {basket.items?.map((items) => (
            
                 <div className={ !openBag  ? 'bag_body' : 'bag_bodyShow'}>
                    <Link to= {`/collections/${items.title}/products/${items.title}?_id=${items.globalId}`} className='cart_image'>
                        <img  onClick={hideBag1} src={items.url} alt={items.url}/>   
                    </Link>
                    <div className='cart__item-details'>
                        <div className='cart__item-top'>
                        <Link to= {`/collections/${items.title}/products/${items.title}?_id=${items.globalId}`} >
                            <span id="span"  onClick={hideBag1}>  {items.title} - {items.color} </span>
                        </Link>
                            <span id="taille" >Taille:<span  id="size" > {items.size} </span> </span>
                        </div>
                        <div className='cart__item-bottom'>
                            <div className='product_quantity'  >
                              <BiMinus id="icon" 
                              onClick={() =>   
                                basket.removeOneFromBasket(items.id , items.id , 1)}
                                /> 
                                        {items.quantity < items.quantityMax ? items.quantity : items.quantityMax } 
                                <BiPlus  id="icon"   style={ ( items.quantityMax === items.quantity)  ?{color:"#989898" , cursor:"not-allowed"} : null}
                                       onClick={() =>   
                                       basket.addOneToBasket(items.id , items.id ,1) } /> 
                          </div>
                          <div className='product_price'>
                               <span> {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(items.price * (items.quantity < items.quantityMax ? items.quantity : items.quantityMax )* rates ).toFixed(2) } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>
                          </div>
                            </div>
                        </div>
                    </div>

        ) )}

                </div>
                
                  <div className={ !openBag ? 'bag_checkout' : 'bag_checkout_open'}>
                    <div className='subtotal'>
                       <span> SOUS-TOTAL  </span>
                       <span id="price">   {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } {(total * rates).toFixed(2)} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }  </span>
                    </div>
                      <div className='button_checkout'>
                    { loader ?
                          <AiOutlineLoading3Quarters className="loader"/>
                        :
                        <span onClick={CreateOrder}
                        >    étape suivante      </span>}
                    </div>
                  </div>
                  </>   
                  : 
                  <span class={!openBag  ? "empty" : "emptyOpen"}> Votre panier est vide </span>                 
                }
             </div>
                    
                </div>
          
    </div>
  )
}

export default Bag
