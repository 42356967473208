import React from 'react'
import Nav_top from '../Nav_top/Nav_top'
import '../Search/Search.scss'
import  {useState} from "react"
import axios from 'axios'
import {useEffect} from "react"
import Paginations from '../Paginations/Paginations'
import ProductItem from './ProductItem/ProductItem'
import Sidebar from '../components/Sidebar/Sidebar'
import {  useLocation, useSearchParams } from 'react-router-dom'
import Footer from "../components/Footer/Footer"

const Search = ({scroll , openBag , setOpenBag , openShop , setOpenShop , rates , selectedCurrency}) => {

  const [searchProduct , setSearchProduct] = useState([]);
  const [currentPage , setCurrentPage] = useState(1);
  const [valueOfOption , setValueOfOption] = useState("pertinence");
  const [highestValue , setHighestValue] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [color , setColor] = useState('All')
  const response = {data: {showlogo: 0}}
  const [availability, setAvailability] = useState(response.data.showlogo);
  const [selectedFilter , setSelectedFilter] = useState(false)
  const [colorArray , setColorArray] = useState([])
  const {pathname} = useLocation()

  const params = new URLSearchParams(window.location.search)
  

  const searchValue = params.get('productName')


  useEffect(() => {
    axios.get(`https://caftannsapi-6.onrender.com/api/products/product/searchBytitle/pricess?produitTitre=${searchValue}`)
    .then(response => {
        setHighestValue(response.data)
    })
  }, [searchValue ])
  
  // value 

  console.log(highestValue)



  const [value, setValue] =  useState([0 , 10000]) ;


/// on fetch le data from le serveur en fonction de 3 filter
useEffect(() => {
  axios.get(`https://caftannsapi-6.onrender.com/api/products/product/searchBytitle?produitTitre=${searchValue}&sort_by=${valueOfOption}&low=${value[0]}&high=${value[1]}&color=${colorArray.length === 0 ? color : colorArray}&availabilityB=${availability}`)
  .then(response => {
      setSearchProduct(response.data)
  })
}, [searchValue  ,valueOfOption , value[0] , color , colorArray , value[1] , availability  ])


// on change a chaque fois l'url si le la valeur low chnage 
useEffect(() => {
  searchParams.set("low", value[0] );
  setSearchParams(searchParams);
 
}, [value[0]] , value[1] )

useEffect(() => {
  searchParams.set("high", value[1] );
  setSearchParams(searchParams);
}, [value[1]])



useEffect(() => {
  searchParams.set("availabilityB", availability );
  setSearchParams(searchParams);
}, [availability])



const nmbrMax = 12;
const NbrPages = Math.ceil((searchProduct?.length + 1 )/ nmbrMax)
const startIndex = (currentPage -1 ) * nmbrMax ; // 0*12 =0
const finishIndex = (currentPage * nmbrMax ) // 1*12 = 12
const ordredProduct = (searchProduct)?.slice(startIndex, finishIndex)



let quantity = 0
let quatId = ""
searchProduct?.map((product) => (
 product?.productDetails?.map((detail) => (
    detail.details?.map((item) => {
        item.variables?.map((variable) => {
            for(let i=0 ; i<product.length ; i++) {
                quantity +=  variable.quantity
                return quantity
            }
        })
      })
 ))
))

useEffect(() => {
  // "document.documentElement.scrollTo" is the magic for React Router Dom v6
  document.documentElement.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth" , // Optional if you want to skip the scrolling animation
  });
}, [pathname]);

  return (
    <div className='search-page'>
      <div className='navbar' id="navbar"  >
         <Nav_top rates={rates} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll}/>
      </div>
      <div style={{maxWidth:"1500px" , marginLeft:"auto" , marginRight:"auto"}}>
                   <Sidebar rates={rates} selectedCurrency={selectedCurrency} colorArray={colorArray} setColorArray={setColorArray}  selectedFilter={selectedFilter}  setSelectedFilter={setSelectedFilter}  availability={availability} 
                   setAvailability={setAvailability} color={color} setColor ={setColor} searchParams={searchParams} 
                   setSearchParams={setSearchParams} searchValue={searchValue} scroll={scroll}
                     value={value} setValue={setValue} highestValue={highestValue} setHighestValue={highestValue} />
            
                       <ProductItem rates={rates} selectedCurrency={selectedCurrency} setOpenShop={setOpenShop} openShop={openShop} openBag={openBag} setOpenBag={setOpenBag} scroll={scroll} selectedFilter={selectedFilter}  setSelectedFilter={setSelectedFilter}  params={params} searchProduct={searchProduct} ordredProduct={ordredProduct} valueOfOption={valueOfOption}  setValueOfOption ={setValueOfOption} />
                       <div className='pagination'>
                                   <Paginations NbrPages={NbrPages} currentPage={currentPage} setCurrentPage={setCurrentPage} 
                                   searchProduct={searchProduct} setSearchProduct={setSearchProduct}/>
                      </div>
     </div>
     <Footer/>
    </div>
  )
}

export default Search
