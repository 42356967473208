import React from 'react'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import './Shipping.scss'
import { useRef } from 'react'
import { BasketContext } from '../../Context/BasketContext/Basket'
import { useState } from 'react'
const Shipping = ({setStep , step , setPriceShipping ,total , infos , infosOrder , handleChangeValues , setNameShipper , rates , selectedCurrency}) => {


    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const basket = useContext(BasketContext).items


    var radios = document.getElementsByName('shipping');

    for (var i = 0, length = radios.length ; i < length; i++) {
        if (radios[i].checked) {
          // do whatever you want with the checked radio
          setPriceShipping(Number(radios[i].value))
          setNameShipper(radios[i].id)

        }
      }

      var sumPro =0
      var sumProColissimo = 0

basket.map((item) => {
     sumPro += item.quantity
     sumProColissimo += item.quantity
})

console.log(rates)

  return (
    <div className='shpping_section'>
        <div className='shpping_infos'>
            <div className='shpping_infos_items'>
                <span> Contact</span>
                <div className='shipping_values'>
                  <span> {infosOrder.email}  </span>
                 <span onClick={() => setStep(2)} className='edit'>Modifier</span>
                </div>
            </div>
            <div className='divider'></div>
            <div className='shpping_infos_items'>
                <span> Expédier à</span>
                <div className='shipping_values'>
                  <span> {infosOrder.adress} {infosOrder.adress2}  </span>
                 <span onClick={() => setStep(2)} className='edit'>Modifier</span>
                </div>
            </div>
        </div>
        <span className='expidition'> Mode d'expédition  </span>

         { infosOrder.country === 'France' ?
               <div className='shpping_infos' >
            { (total / rates )   < 300 ?
                   <>
                  <div className='shpping_infos_items' onClick={() => ref1.current.checked = true }>
                      <input 
                              defaultChecked 
                              type="radio" 
                              name="shipping"  
                              id="Mondial relay France - 4 à 5 jours ouvrés (Mondial Relay)"
                              value={ (sumPro <= 1) ? (7.49 * rates ): (sumPro <= 2) ? (11.10 * rates )
                                      : (sumPro <= 4) ? (13.90 *rates) :(sumPro <= 5) ? (20 * rates) :(sumPro <= 11) ? (21.90 * rates ): (28.90 * rates) } 
                              ref={ref1} 
                      />
                      <div className='shipping_values'>
                            <span> Mondial relay France - 4 à 5 jours ouvrés (Mondial Relay) </span>
                            <span  className='value'>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } { (sumPro <= 1) ? `${(7.49 * rates).toFixed(2)}` : (sumPro <= 2) ? `${(11.10 * rates).toFixed(2)}`
                                          : (sumPro <= 4) ? `${(13.90*rates).toFixed(2)}`:(sumPro <= 5) ? `${(20 * rates).toFixed(2)}`:(sumPro <= 11) ? `${(21.90 * rates).toFixed(2)}` : `${(28.90 * rates).toFixed(2)}`} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }</span>
                      </div>
                  </div>
                  <div className='divider' style={{width:"100%"}}></div>
                  <div className='shpping_infos_items' onClick={() => ref2.current.checked = true } >
                      <input 
                              id='Colissimo - sans signature - 48h à 72h jours ouvrés '    
                              type="radio" 
                              name="shipping" 
                              value={ (sumProColissimo <= 1) ? (rates * 9.29) : (sumProColissimo <= 3) ? (14.65 * rates)
                              :(sumProColissimo <= 5) ?( rates * 21.30)  :(sumProColissimo <= 8) ? (26.95 * rates) :(  rates * 33.40)} 
                              ref={ref2} 
                      />
                      <div className='shipping_values'>
                            <span>Colissimo - sans signature - 48h à 72h jours ouvrés </span>
                            <span  className='value'>   {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } { (sumProColissimo <= 1) ? `${(9.29 * rates ).toFixed(2)}` : (sumProColissimo <= 3) ? `${(14.65 * rates)}` 
                              :(sumProColissimo <= 5) ? `${(21.30 * rates).toFixed(2)}`:(sumProColissimo <= 8) ? `${(26.95 * rates).toFixed(2)}` : `${(33.40 * rates).toFixed(2)}`} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }</span>
                      </div>
                  </div>
                </>
                :
               ( 
                <div className='shpping_infos_items' onClick={() => ref3.current.checked = true }>
                      <input 
                              defaultChecked 
                              type="radio" 
                              name="shipping"  
                              id="Mondial relay France - 4 à 5 jours ouvrés (Mondial Relay)"
                              value={ (sumPro <= 1) ? (0 * rates ): (sumPro <= 2) ? (0 * rates )
                                : (sumPro <= 4) ? (0 *rates) :(sumPro <= 5) ? (0 * rates) :(sumPro <= 11) ? (0 * rates ): (0 * rates) } 
                              ref={ref3} 
                      />
                      <div className='shipping_values'>
                            <span> Mondial relay France - 4 à 5 jours ouvrés (Mondial Relay) </span>
                            <span  className='value'>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } { (sumPro <= 1) ? `${(7.49 * rates).toFixed(2)}` : (sumPro <= 2) ? `${(11.10 * rates).toFixed(2)}`
                                          : (sumPro <= 4) ? `${(0*rates).toFixed(2)}`:(sumPro <= 5) ? `${(0 * rates).toFixed(2)}`:(sumPro <= 11) ? `${(0 * rates).toFixed(2)}` : `${(0 * rates).toFixed(2)}`} {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   }</span>
                      </div>
                  </div>
               ) 
                }
       
            </div>

            :  ( infosOrder.country === "Belgium" || infosOrder.country === "Spain" || infosOrder.country === "Luxembourg" || infosOrder.country === "Italy"  
                                
                || infosOrder.country === "Netherlands"  || infosOrder.country === "Germany"  || infosOrder.country === "Portugal" 
                                
            ) ?
             <div className='shpping_infos' >
            <div className='shpping_infos_items' onClick={() => ref1.current.checked = true }>
                 <input 
                        id='Mondial relay Europe - 4 à 5 jours ouvrés (Mondial Relay) '        
                        defaultChecked 
                        type="radio"
                         name="shipping" 
                        value={ (sumPro <= 1) ? (12.21 * rates) : (sumPro <= 2) ?( 13.10 * rates)
                                : (sumPro <= 4) ? (16.90 * rates) :(sumPro <= 5) ?( 20 * rates):(sumPro <= 11) ? (21.90 * rates) : (43.90 * rates) } 
                        ref={ref1}
                  />
                <div className='shipping_values'>
                      <span> Mondial relay Europe - 4 à 5 jours ouvrés (Mondial Relay) </span>
                      <span  className='value'>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null } { (sumPro <= 1) ? `${(12.21 * rates).toFixed(2)}`: (sumPro <= 2) ? `${(13.10 * rates).toFixed(2)} `
                                    : (sumPro <= 4) ? `${(16.90 * rates).toFixed(2)}`:(sumPro <= 5) ? ` ${(20 * rates).toFixed}` :(sumPro <= 11) ? `${(21.90 * rates).toFixed(2)}` : `${(43.90 * rates).toFixed(2)}` } {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>
                </div>
            </div>
   
        </div>
        :   <div className='shpping_infos' >
        <div className='shpping_infos_items' onClick={() => ref1.current.checked = true }>
             <input
                     id='Delinvengo Monde - 4 à 5 jours ouvrés (Mondial Relay)'       
                     defaultChecked
                     type="radio"
                     name="shipping" 
                     value={ (sumPro <= 2 ) ? (43.90 * rates) : (sumPro <= 3) ? (47.33 * rates)
                    : (sumPro >= 4) && (77.90 * rates) } 
             
             ref={ref1} />
            <div className='shipping_values'>
                  <span> Delinvengo Monde - 4 à 5 jours ouvrés (Mondial Relay) </span>
                  <span  className='value'>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(sumPro <= 2 ) ? "43.90" : (sumPro <= 3) ? `${(47.33 * rates).toFixed(2)}`
                  : (sumPro >= 4) && `${(77.90 * rates).toFixed(2)}` }  {selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span>
            </div>
        </div>

    </div>
}

    </div>
  )
}

export default Shipping
