import { createContext } from "react";
import { useReducer , useEffect } from "react";
import RegisterReducer from "./RegisterReducer";

const INIT_STATE = {
    register :   [],
    isFetching : false,
    error:false
}

export const RegisterContext = createContext(INIT_STATE);

export const RegisterContextProvider = ({children})  => {

    const [state , dispatch] = useReducer(RegisterReducer , INIT_STATE);

  
    
    return (
        <RegisterContext.Provider
        value={{
            register : state?.register,
            isFetching: state?.isFetching,
            error: state?.error,
            dispatch ,
        }}
        >
        {children}
        </RegisterContext.Provider>    

    );


}