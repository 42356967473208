import React from 'react'
import Nav_top from '../../Nav_top/Nav_top'
import './SizeGuide.scss'
import image from "../../images/size.png"
import Footer from "../../components/Footer/Footer"
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SizeGuide = ({openBag , setOpenBag , rates}) => {
  const {pathname} = useLocation()
  useEffect(() => {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth" , 
      });
    }, [pathname]);

  return (
    <div className='size_guide'>
         <Nav_top rates={rates} openBag={openBag} setOpenBag={setOpenBag}/>
         <div className='size_wrapper'> 
            <div className='size_container'> 
            <span className='title'>  guide des tailles </span>
                <div className='size_left'> 
                    <div className='size_title_section'> 
                        <h4> GUIDE DE MESURE  </h4>
                        <span>Utilisez notre guide de mesure pour vous assurer de la taille à prendre.</span>
                        <span>   Pour toute aide, contactez-nous à  <a className='contact' href="mailto: nsCollection.tls@gmail.com" > nsCollection.tls@gmail.com ! </a> </span>
                    </div>
                    <img src={image} alt={"Ns Caftan"}/>
                    <div className='size_title_section'> 
                      <h4> GUIDE DE LONGUEUR  </h4>
                      <span className='long'>   Ne mesurez pas à partir de votre tête ! Mesurez de l'épaule vers le bas pour connaître la longueur de la robe.    </span>
                   </div>
                   <div className='size_title_section'  > 
                      <h4 style={{fontSize:"16px"}}> LE SERVICE CLIENT À VOTRE DISPOSITION !  </h4>
                      <span className='long'>  N'hésitez pas à nous envoyer un message via whatsapp avec le lien ou la photo de votre commande pour avoir
                                               la confirmation du délai de livraison de votre panier au numéro suivant : +33771553049  </span>
                                               <span>     Vous pouvez nous écrire 7j/7, 24h/24 :  <a className='contact' href="mailto: nsCollection.tls@gmail.com" > nsCollection.tls@gmail.com ! </a></span>
                    </div>
                </div>
            </div>
         </div>
         <Footer/>
    </div>
  )
}

export default SizeGuide
