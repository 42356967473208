import { TextField } from '@material-ui/core'
import React from 'react'
import { logout } from '../../Context/LoginContext/LoginActions'
import { Link } from 'react-router-dom'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalButton } from 'react-paypal-button-v2'

import { useState } from 'react'

const Informations = ({dispatch , infos , unique , infosOrder , setInfosOrder , handleChangeValues , stepAction , phoneNum , setPhoneNum}) => {

      if(infosOrder.country === undefined) {
        infosOrder.country = 'France'
      }

            if(infosOrder.email === undefined) {
                infosOrder.email =  infos?.others?.Email
            }


         
            const handleChange = (e) => {
                var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
                var regex = /^\+(?:[0-9] ?){10,14}[0-9]$/;
                const value = e.target.value
                if (value.match(phoneno) || value.match(regex)) {
                    setPhoneNum(true);
                }
                else {
                    setPhoneNum(false)
                }
              };


              const cart = [
                {id: 2, firstImage: "image1.jpg", total: 440, count: 2, name: "Item2"},
                {id: 14, firstImage:"image2.jpg", total: 220, count: 1, name: "Item2"}];
                
               const selectedShippingOption ="EU";
               const cartData = () => {
                let currentCart = [...cart];
                let filteredCart = currentCart
                 .map((elem) => {
                  const { id, count, name, price } = elem;
                  return { id, count, name, price };
                 });
                const cartData = { filteredCart, selectedShippingOption };
                return cartData;
               };

              const createOrder = () => { 
                return fetch(`${window.api_url}/api/payment`, { 
                 method: "POST", 
                 body: JSON.stringify(cartData), 
                 headers: { 
                 "content-type": " application/json", 
                 }, 
                }) 
                 .then((res) => { 
                  return res.json(); 
                 }) 
                 .catch(() => { 
                  alert("Error"); 
                 }) 
                 .then((data) => { 
                  return data.orderID ; }    ); } 

      
  return (
    <div className='inforamtion_section'>
    <div className='express_checkout'>
        <div className='express_checkout_title'>
            <div className='divider'> </div>
            <span >Attention : pour payer par carte bancaire, poursuivre votre commande</span>
            <div className='divider'> </div>
        </div>
        <div className='buttons'>
            <div className='paypal' >
            <PayPalScriptProvider options={{ "client-id": "AUWLJQM8Oe6PERKrRm8TliUAbgMB8Q6PZuAVuC1Conz6jjuzLrPrLhS6WEJ7tJSxKuQQnXUtaXBgOIa3" }}>
                 <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: "1230.00",
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then((details) => {
                        const name = details.payer.name.given_name;
                        alert(`Transaction completed by ${name}`);
                    });
                }}
            />
        </PayPalScriptProvider>
            </div>
        </div>
        <div className='express_checkout_title'>
            <div style={{width:"100%"}} className='divider'> </div>
            <span  style={{width:"auto" , fontSize:"15px"}}  >OU</span>
            <div  style={{width:"100%"}} className='divider'> </div>
        </div>
        <div className='form_infos'>
    {  infos?.others?.Email ?
            <div className='form_infos_details'>
                 <h2 className='title'> Coordonnées </h2>
                 <span className='email' > {infos?.others?.FName} {infos?.others?.LName} ({infos?.others.Email}) </span>
                 <span className='disconnect' onClick={()  =>{  dispatch(logout())} } > Déconnexion </span>
                 <div className='newsletter'>
                 <input type='checkbox'/>
                     <span className='news' > Envoyez-moi les nouvelles et les offres par e-mail </span>
                 </div>
            </div>
        :
        <div className='form_infos_details'>
            <div className='title_wrapper'>
                <h2 className='title'> Coordonnées </h2>
                <span> Vous avez déjà un compte ? <Link to={'/account/login'}  className='disconnect'>Ouvrir une session</Link> </span>
            </div>
            <TextField
            id="filled-email-input"
            label="Adresse e-mail"
            type='email'
            variant="filled"
            name="email"
            onChange={handleChangeValues}
            value={infosOrder.email}
            error={((infosOrder.email === undefined || infosOrder.email === "" ) && stepAction )? true : false }
            helperText={((infosOrder.email === undefined || infosOrder.email === "" ) && stepAction )? "Saisissez une adresse e-mail" : null}
          />
        </div>
        }
         <div className='form_infos_adress'>
                  <h2 className='title'> Adresse d’expédition  </h2>
                  <TextField
                  select
                  SelectProps={{
                      native: true,
                  }}
                  variant="filled"
                  label="Pays/région"
                  defaultChecked="France"
                  autoFocus={true}
                  required
                  name="country"
                  onChange={handleChangeValues}
                  value={infosOrder.country}
                  
                 >
              <option selected className='options'  value="France"> France </option>
              <option  className='options'  value="Belgium"> Belgium </option>
              <option  className='options'  value="Spain"> Spain </option>
              <option  className='options'  value="Luxembourg"> Luxembourg </option>
              <option  className='options'  value="Italy"> Italy </option>
              <option  className='options'  value="Netherlands"> Netherlands </option>
              <option  className='options'  value="Germany"> Germany </option>
              <option  className='options'  value="Portugal"> Portugal </option>
              <option  className='options'  value="Morocco"> Morocco </option>
              <option  className='options' disabled>  - - - - - - -</option>
               
              {unique.map((option) => (
                  <option className='options' key={option} value={option}>
                  {option}
                  </option>
              ))}
                </TextField>
                  <div className='separator' >
                            <TextField
                            id="filled-name-input"
                            label="Prénom"
                            type='text'
                            variant="filled"
                            required
                            name='FName'
                            onChange={handleChangeValues}
                            value={infosOrder.FName}
                            error={((infosOrder.FName === undefined || infosOrder.FName== "" ) && stepAction )? true : false }
                            helperText={((infosOrder.FName === undefined || infosOrder.FName== "" ) && stepAction )? "Entrez un prénom" : null}
                          />  
            
                            <TextField
                            id="filled-name-input"
                            label="Nom"
                            type='text'
                            variant="filled"
                            required
                            name='LName'
                            onChange={handleChangeValues}
                            value={infosOrder?.LName}
                            error={((infosOrder.LName === undefined || infosOrder.LName== "")  && stepAction )? true : false }
                            helperText={((infosOrder.LName === undefined || infosOrder.LName== "")  && stepAction )? "Entrez un nom" : null}


                        />
                  </div>
                  <TextField
                  id="filled-adress-input"
                  label="Adresse"
                  type='text'
                  variant="filled"
                  required
                  name='adress'
                  onChange={handleChangeValues}
                  value={infosOrder.adress}
                  itemType='adress'
                  error={((infosOrder.adress === undefined || infosOrder.adress== "" ) && stepAction )? true : false }
                  helperText={((infosOrder.adress === undefined || infosOrder.adress== "" ) && stepAction )? "Entrez une adresse" : null}

                />
                      <TextField
                  id="filled-adress-input"
                  label="Appartement, suite, etc. (optionnel)"
                  type='text'
                  variant="filled"
                  name='adress2'
                  onChange={handleChangeValues}
                  value={infosOrder.adress2}
                />
                <div className='separator'>
                    <TextField
                    id="filled-zip-input"
                    label="Code postal"
                    type='text'
                    variant="filled"
                    required
                    name='code_postal'
                    onChange={handleChangeValues}
                    value={infosOrder.code_postal}
                    error={((infosOrder.code_postal === undefined || infosOrder.code_postal== "" ) && stepAction )? true : false }
                    helperText={((infosOrder.code_postal === undefined || infosOrder.code_postal== "" ) && stepAction )? "Entrez un code postal" : null}

                />
                    <TextField
                    id="filled-city-input"
                    label="Ville"
                    type='text'
                    variant="filled"
                    required
                    aria-required
                    name='city'
                    onChange={handleChangeValues}
                    value={infosOrder.city}
                    error={((infosOrder.city === undefined || infosOrder.city== "")  && stepAction )? true : false }
                    helperText={((infosOrder.city === undefined || infosOrder.city== "")  && stepAction )? "Saisissez une ville" : null}
                />
              </div>

                <TextField
                        id="filled-number-input"
                        label="Télephone"
                        type='tel'
                        variant="filled"
                        required
                        name='phoneNumber'
                        onChange={handleChangeValues}
                        value={infosOrder.phoneNumber}
                        onInput={handleChange}
                        helperText={((infosOrder.phoneNumber === undefined || infosOrder.phoneNumber === "" || !phoneNum ) && stepAction )? "Entrez un numéro de téléphone valide" : null}
                        error={((infosOrder.phoneNumber === undefined || infosOrder.phoneNumber === "" || !phoneNum ) && stepAction )? true : false }
                        />
                <div className='newsletter'>
                <input type='checkbox'/>
                    <span className='news' > Envoyez-moi les nouvelles et les offres par e-mail </span>
                </div>
             
            </div>
        </div>
    </div>
 </div>
  )
}

export default Informations
