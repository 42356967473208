import React from 'react'
import './Payement.scss'
import paypal from "../../images/PayPal.svg.png"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../../checkoutForm';
import { useState , useEffect } from 'react';
import axios from 'axios';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51MqM4ZBpjSMLg4YDQIuzKb5pn0ghBywnaAA23UHAkYSSKUdxf1oiqjJBk4YjfEDQMECgBEcSB12ZZD3pDlg9DKgz00AQif7Yyd');

const Payement = ({setStep , step , infosOrder , priceShipping  , setCopyInfos ,nameShipper , selectedCurrency   , handleSubmit  , TotalPrice , idOrder , setIdOrder  }) => {

 
  const [clientSecret, setClientSecret] = useState('');
  const [checked , setChecked] = useState("carte");

  console.log(infosOrder.adress , "data")

   const amount = Math.floor(TotalPrice * 100 ).toString()
  
    useEffect(()   =>  {
        axios.post('https://caftannsapi-6.onrender.com/api/payment', {
          amount:  Math.floor(amount).toString() ,
          currency: selectedCurrency,
       
        })
          .then((response) => {
            const data = response.data;
            setClientSecret(data.clientSecret);
            console.log(clientSecret, "client");
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);
  
    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };


  

  return (
    <div className='payement_section'>
          <div className='payement_infos'>
            <div className='payement_infos_items'>
                <span> Contact</span>
                <div className='payement_values'>
                  <span> {infosOrder.email} </span>
                 <span onClick={() => setStep(2)} className='edit'>Modifier</span>
                </div>
            </div>
            <div className='divider'></div>
            <div className='payement_infos_items'>
                <span> Expédier à</span>
                <div className='payement_values'>
                  <span>{infosOrder.adress} {infosOrder.adress2}</span>
                 <span onClick={() => setStep(2)} className='edit'>Modifier</span>
                </div>
            </div>
            <div className='divider'></div>
            <div className='payement_infos_items'>
                <span> Méthode </span>
                <div className='payement_values'>
                  <span> {nameShipper}<span style={{fontWeight:600 , color:"#333"}}>  {selectedCurrency === "USD" ? "$" : selectedCurrency === "AED" ? "Dhs."  : selectedCurrency =="GBP" ? "£"  : selectedCurrency === "QAR" ? "QAR" : null }  {(priceShipping).toFixed(2)}{selectedCurrency === "EUR" ? "€" :  selectedCurrency === "MAD" ? "dh"  : null   } </span></span>
                  <span onClick={() => setStep(3)} className='edit'>Modifier</span>
                </div>
            </div>
        </div>
        <div className='titles'> 
          <span className='paiement'> Paiement  </span>
          <span className='subscription'> Toutes les transactions sont sécurisées et chiffrées.</span>

          <div className='paiement_method_container'>
              <div className={checked === "carte" ? 'paiement_method_wrapper checkedOption' : 'paiement_method_wrapper'  } onClick={() => setChecked('carte') }  >
                 <div className='wrapper_items'>
                         <input 
                             defaultChecked 
                             type="radio" 
                             name="payment"  
                             id="Mondial relay France - 4 à 5 jours ouvrés (Mondial Relay)"
                             className='carte_credit'
                            checked={checked === 'carte'}

                     />
                    <div className='paiement_values'>
                          <span> Carte de crédit/débit </span>
                          <div  >
                              <img alt="" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg" role="img" width="38" height="24" class="_1tgdqw61 _1fragem46 _1fragem4b _1fragem4l _1fragem4g _1fragemat _1fragemap _1fragemb2 _1fragem33"/>
                              <img alt="" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/5e3b05b68f3d31b87e84.svg" role="img" width="38" height="24" class="_1tgdqw61 _1fragem46 _1fragem4b _1fragem4l _1fragem4g _1fragemat _1fragemap _1fragemb2 _1fragem33"/>
                              <img alt="" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/f11b90c2972f3811f2d5.svg" role="img" width="38" height="24" class="_1tgdqw61 _1fragem46 _1fragem4b _1fragem4l _1fragem4g _1fragemat _1fragemap _1fragemb2 _1fragem33"/>
                              <img alt="" src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/ee326777d741b5bb4c0e.svg" role="img" width="38" height="24" class="_1tgdqw61 _1fragem46 _1fragem4b _1fragem4l _1fragem4g _1fragemat _1fragemap _1fragemb2 _1fragem33"/>
                          </div>
                    </div>
                 </div>
              </div>
              <div className={`paiement_method_dropped  ${checked === "carte" && "showDropped" }  `} >
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                   <CheckoutForm setIdOrder={setIdOrder} idOrder={idOrder} selectedCurrency={selectedCurrency} TotalPrice={TotalPrice} priceShipping={priceShipping} setCopyInfos={setCopyInfos} handleSubmit={handleSubmit} infosOrder={infosOrder} email1={infosOrder.email} step={step} setStep={setStep} />
               </Elements>
              )}
              </div>
              
         
          </div>
          {/* <div className='paiement_method_container'>
          <div className={checked === "paypal" ? 'paiement_method_wrapper checkedOption' : 'paiement_method_wrapper'  } onClick={() => setChecked('paypal')} >
             <div className='wrapper_items'>
                     <input 
                         defaultChecked 
                         type="radio" 
                         name="payment"  
                         id="Mondial relay France - 4 à 5 jours ouvrés (Mondial Relay)"
                         className="paypal-payment"
                         checked={checked === 'paypal'}
                 />
                <div className='paiement_values'>
                      <span> Paypal </span>
                      <img src={paypal} alt=''/>
                </div>
             </div>
          </div>
          
     
      </div> */}
        
        </div>
    

    </div>
  )
}

export default Payement
