import { createContext } from "react";
import { useReducer } from "react";
import ReviewReducer from "./ReviewReducer";

const INIT_STATE = {
    review : [],
    isFetching : false,
    error:false
}

export const ReviewContext = createContext(INIT_STATE);

export const ReviewContextProvider = ({children})  => {

    const [state , dispatch] = useReducer(ReviewReducer , INIT_STATE);

    
    return (
        <ReviewContext.Provider
        value={{
            review : state?.review,
            isFetching: state?.isFetching,
            error: state?.error,
            dispatch ,
        }}
        >
        {children}
        </ReviewContext.Provider>    

    );


}