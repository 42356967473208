import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";
import axios from "axios";


// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51MqM4ZBpjSMLg4YDQIuzKb5pn0ghBywnaAA23UHAkYSSKUdxf1oiqjJBk4YjfEDQMECgBEcSB12ZZD3pDlg9DKgz00AQif7Yyd");

export default function Pay() {

    const [clientSecret, setClientSecret] = useState('');
  
    useEffect(()   =>  {
        axios.post('https://caftannsapi-6.onrender.com/api/payment', {
          amount: 11000,
          currency: "usd",
        })
          .then((response) => {
            const data = response.data;
            setClientSecret(data.clientSecret);
            console.log(clientSecret, "client");
          })
          .catch((error) => {
            console.error(error);
          });
      }, []);
  
    const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };

  return (
    <div className="App">
    {clientSecret && (
         <Elements options={options} stripe={stripePromise}>
            <CheckoutForm/>
        </Elements>
    )}
    </div>
  );

}