import React from 'react'
import './Footer.scss';
import logo from '../../images/logoFooter.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { BasketContext } from '../../Context/BasketContext/Basket';

const Footer = () => {


  
  const basket = useContext(BasketContext)
  const navigate = useNavigate()

  const navigateAccount = (e) => {
    e.preventDefault()
    const login = JSON.parse(localStorage.getItem('login_user'))
 
    if(login.length === 0  ) {
      navigate('/account/login')

    } else {
      navigate('/account')
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });

  }

  const handleClick = () =>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  }

  return (
    <div className='footer' >
        <div className='footer_container' >
            <div className='footer_grid_item_img' >
                 <img src={logo} alt='Ns Caftan'/>
            </div>
            <div className='footer_grid_item' >
                <ul className='grid_List' >
                   <li>   <Link   onClick={handleClick}  to={'/pages/CGV'}> CVG</Link> </li>
                   <li>   <Link   onClick={handleClick}  to={'/pages/mentions-legales'}> MENTIONS LÉGALES</Link> </li>
                   <li>    <Link   onClick={handleClick}  to={'/pages/retours-echanges'}> RETOURS & ÉCHANGES</Link> </li>
                   <li>   <Link   onClick={handleClick}  to={'/pages/confidentialite'} > CONFIDENTIALITÉ</Link> </li>
                   <li>   <Link   onClick={handleClick}  to={'/pages/nous-conntacter'}> CONTACTEZ-NOUS</Link> </li>
                </ul>
            </div>
            <div className='footer_grid_item' >
                <ul className='grid_List' >
                   <li>   <Link    onClick={navigateAccount} > MON COMPTE</Link> </li>
                   <li>   <Link    onClick={handleClick}    to={'/pages/faq'} > FAQ</Link> </li>
                   <li>   <Link    onClick={navigateAccount} > MES COMMANDES</Link> </li>
                   <li>   <Link    onClick={navigateAccount} > SUIVRE MA COMMANDE</Link> </li>
                   <li>   <Link     onClick={navigateAccount} > MES ADRESSES</Link> </li>
                </ul>
            </div>
            <div className='footer_grid_item' id="newsletter" >
              <span>Inscription newsletter</span>
              <span id="text">En vous inscrivant à la newsletter, vous acceptez de recevoir nos offres, nouveautés et promotions.
              </span>
              <div className='input_news'>  
                  <input />
                  <span>S'inscrire </span>
              </div>
            </div>
        </div>
        <span>Réalisation & design : @Hamzadev </span>

    </div>
  )
}

export default Footer
